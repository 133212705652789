import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import CloseIcon from "mdi-react/CloseIcon";

const AddEditFarmlandModal = (props) => {
  const { backendUrl } = useAuth();

  const saveFarmland = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/items/farmland`, "POST", "", payload),
    {
      onSuccess: () => {
        toast.success("Farmland created Successfully");
        formik.resetForm({
          farmland: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const replaceFarmland = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/update-farmland-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Farmland deleted Successfully");
        formik.resetForm({
          farmland: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const editFarmland = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/items/edit-farmland/${props?.pickedStorage?.id}`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Farmland updated Successfully");
        formik.resetForm({
          farmland: "",
        });
        props?.refetch && props?.refetch();
        props?.onHide();
      },
      onError: ({ message }) => {
        toast.error(message ? message : "Error!!!", {
          position: "top-right",
        });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      farmland: "",
    },
    validationSchema: yup.object().shape({
      farmland: yup.string().required("Farmland is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: `${props?.editStorage
            ? "Edit"
            : props?.delete_farmland
              ? "Replace"
              : "Create"
            } Farmland`,
          description: `Are you sure you want to ${props?.editStorage
            ? "Edit"
            : props?.delete_farmland
              ? "Replace"
              : "Create"
            } this farmland `,
        })
      ) {
        // console.log(values);
        if (props?.editStorage) {
          editFarmland.mutate(values);
        } else if (props?.delete_farmland) {
          replaceFarmland.mutate({
            farmland: values.farmland,
            delete_farmland: props?.delete_farmland,
          });
        } else {
          saveFarmland.mutate(values);
        }
      }
    },
  });

  useEffect(() => {
    if (props?.editStorage) {
      formik.setValues({
        farmland: props?.pickedStorage?.farmland,
      });
    }
  }, []);

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>
                {props?.editStorage
                  ? "Edit"
                  : props?.delete_farmland
                    ? "Replace"
                    : "Create"}{" "}
                Farmland
              </h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">
              {props?.editStorage
                ? "Edit"
                : props?.delete_farmland
                  ? "Replace the deleted"
                  : "Create a new"}{" "}
              Farmland
            </p>
          </div>
          <div className="mt-3">
            {/*  */}
            {!props?.delete_farmland && (
              <div className="mb-3">
                <label className="col-form-label fw-bold">Farmland</label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={formik.values.farmland}
                    name="farmland"
                    onChange={formik.handleChange}
                    placeholder="Enter Farmland"
                  />
                  <span className="form-control-feedback text-danger">
                    {formik.errors.farmland}
                  </span>
                </div>
              </div>
            )}

            {/*  */}
            {props?.delete_farmland && (
              <>
                <small className="my-2 text-danger">
                  This Farmland is attached to items
                </small>
                <div className="mb-3">
                  <label className="col-form-label fw-bold">
                    Create/Select Farmland
                  </label>
                  <div className="form-group">
                    <CreatableSelect
                      isClearable
                      onChange={(selected) => {
                        formik.setFieldValue("name", selected?.value);
                      }}
                      // onCreateOption={saveWarehouseContent}
                      placeholder="Create or Select Farmland"
                      options={props?.farmlands}
                      value={props?.farmlands?.find(
                        (el) => el.value === formik.values.farmland
                      )}
                    />
                    <span className="form-control-feedback text-danger">
                      {formik.errors.farmland}
                    </span>
                  </div>
                </div>
              </>
            )}

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        {!props?.delete_farmland && (
          <button className="btn bg print mx-4" onClick={props.onHide}>
            Cancel
          </button>
        )}
        <button className="btn btn-primary" onClick={formik.handleSubmit}>
          {props?.editStorage
            ? "Edit"
            : props?.delete_farmland
              ? "Replace"
              : "Save"}{" "}
          Farmland
        </button>
      </div>
      <ModalLoader
        show={
          saveFarmland.isLoading ||
          editFarmland.isLoading ||
          replaceFarmland.isLoading
        }
      />
    </Modal>
  );
};

export default AddEditFarmlandModal;

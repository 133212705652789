import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";

export default function UpdateVendorUser(props) {
  const {
    user: authUser,
    setUser,
    authVendor,
    setAuthVendor,
    vendorApi,
  } = useAuth();
  const [activeKey, setActiveKey] = useState("password");
  const [showText, setShowText] = useState({
    password: false,
    confirmpassword: false,
    oldPassword: false,
  });

  const backendUrl = useBackendUrl();
  // const token = useToken();
  //   console.log(authVendor, vendorApi);
  //   console.log(vendorApi);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [newsignaturePics, setnewSignaturePics] = useState("");

  const updateAccount = async (values) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${authVendor?.vendorApi}/api/vendors/update-user-account`,
        {
          method: "POST",
          body: values,
        }
      );

      if (!response.ok) {
        response = await response.json();
        if (response.errors) formik.setErrors(response.errors);
        toast.error(response.message);
      } else {
        response = await response.json();
        console.log(response.data.user);
        setAuthVendor({ ...authVendor, ...response.data.user });
        toast.success("Success");
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      Name: "",
      email: "",
      profilePics: "",
      Vendor_ID: authVendor.Vendor_ID,
    },
    validationSchema: yup.object().shape({
      Name: yup.string().required(),
      email: yup.string().required(),
    }),
    onSubmit: (values) => {
      const { profilePics, ...rest } = values;

      const formData = new FormData();
      for (const [key, value] of Object.entries(rest)) {
        formData.append(key, value);
      }

      if (profilePics) {
        formData.append(
          "profilePics",
          profilePics,
          `${rest.Name}${rest.Vendor_ID}_profilePics`
        );
      }

      updateAccount(formData);
    },
  });

  const OnChangeInput = (e) => {
    const { files } = e.target;
    formik.setFieldValue("profilePics", files[0]);
  };

  // ---------------------------

  //-----------------------------

  useEffectOnce(() => {
    setUp();
  });

  const setUp = () => {
    formik.setFieldValue("Name", authVendor.CompanyName);
    formik.setFieldValue("email", authVendor.Email);
  };

  // ----------------------------

  const formikPassword = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      Vendor_ID: authVendor.Vendor_ID,
      //   username: authUser.username,
    },
    validationSchema: yup.object().shape({
      oldPassword: yup.string().required(),
      newPassword: yup.string().required(),
      confirmNewPassword: yup.string().required(),
    }),
    onSubmit: async (values) => {
      const {
        Vendor_ID,
        oldPassword,
        // username,
        confirmNewPassword,
        newPassword,
      } = values;

      if (confirmNewPassword !== newPassword) {
        formikPassword.setFieldError(
          "confirmNewPassword",
          "Mis Match Password"
        );

        formikPassword.setFieldError("newPassword", "Mis Match Password");
        return;
      }

      const data = {
        oldPassword,
        newPassword,
        Vendor_ID,
        // username,
      };

      try {
        setIsPasswordLoading(true);
        let res = await fetch(
          `${authVendor?.vendorApi}/api/vendors/change-password`,
          {
            method: "POST",
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            body: JSON.stringify(data),
          }
        );

        setIsPasswordLoading(false);
        if (!res.ok) {
          res = await res.json();
          if (res.errors) formikPassword.setErrors(res.errors);
          toast.error(res.message);
        } else {
          toast.success("Password Updated Successfully");
        }

        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
  });

  //   const sendPics = () => {
  //     if (newsignaturePics) {
  //       const formData = new FormData();
  //       formData.append(
  //         "signatures",
  //         newsignaturePics,
  //         `${authUser.Staff_ID}_signatures`
  //       );
  //       formData.append("Staff_ID", authUser.Staff_ID);

  //       updateAccount(formData);
  //     } else {
  //       toast.error("Change Signature Before You Send");
  //     }
  //   };

  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Account Editor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <div className="col-md-4 text-center p-3">
            <div className="d-flex justify-content-center">
              <Avatar
                name={authVendor?.ContactName}
                company={authVendor?.CompanyName}
                style={{ width: "10rem", height: "10rem" }}
                onFileUpload={OnChangeInput}
                image={formik.values.profilePics}
                userId={authVendor?.Vendor_ID}
                vendor
              />


            </div>

            <div className="mt-2 fw-bold">Profile picture</div>
          </div>

          <div className="col px-4">
            <Nav
              variant="tabs"
              defaultActiveKey="password"
              onSelect={(key) => setActiveKey(key)}
              className="mb-3"
            >
              <Nav.Item>
                <Nav.Link eventKey="account">Account</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="password">Password</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="signature">Signature</Nav.Link>
              </Nav.Item> */}
            </Nav>
            {activeKey === "account" && (
              <Form className="col" noValidate onSubmit={formik.handleSubmit}>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="username"
                    value={authUser.username}
                    readOnly
                  />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="Name"
                    value={formik.values.Name}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.Name && !!formik.errors.Name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  disabled={isLoading}
                  variant="primary"
                  className="my-3"
                  // onClick={formik.handleSubmit}
                  type="submit"
                >
                  {isLoading ? "Please wait…" : "Save Changes"}
                </Button>
              </Form>
            )}
            {activeKey === "password" && (
              <Form
                className="col"
                noValidate
                onSubmit={formikPassword.handleSubmit}
              >
                <Form.Group className="mb-3">
                  <Form.Label>Old Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showText.oldPassword ? "text" : "password"}
                      name="oldPassword"
                      placeholder="••••••••"
                      value={formikPassword.values.oldPassword}
                      onChange={formikPassword.handleChange}
                      isInvalid={
                        formikPassword.touched.oldPassword &&
                        !!formikPassword.errors.oldPassword
                      }
                    />
                    {showText.oldPassword ? (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, oldPassword: false })
                        }
                      >
                        <EyeOutline />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, oldPassword: true })
                        }
                      >
                        <EyeOffOutline />
                      </InputGroup.Text>
                    )}
                  </InputGroup>

                  {formikPassword.errors.oldPassword && (
                    <span className="text-danger">
                      {formikPassword.errors.oldPassword}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showText.password ? "text" : "password"}
                      name="newPassword"
                      placeholder="••••••••"
                      value={formikPassword.values.newPassword}
                      onChange={formikPassword.handleChange}
                      isInvalid={
                        formikPassword.touched.newPassword &&
                        !!formikPassword.errors.newPassword
                      }
                    />
                    {showText.password ? (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, password: false })
                        }
                      >
                        <EyeOutline />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, password: true })
                        }
                      >
                        <EyeOffOutline />
                      </InputGroup.Text>
                    )}
                  </InputGroup>

                  {formikPassword.errors.newPassword && (
                    <span className="text-danger">
                      {formikPassword.errors.newPassword}
                    </span>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm New Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showText.confirmpassword ? "text" : "password"}
                      placeholder="••••••••"
                      name="confirmNewPassword"
                      value={formikPassword.values.confirmNewPassword}
                      onChange={formikPassword.handleChange}
                      isInvalid={
                        formikPassword.touched.confirmNewPassword &&
                        !!formikPassword.errors.confirmNewPassword
                      }
                    />
                    {showText.confirmpassword ? (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, confirmpassword: false })
                        }
                      >
                        <EyeOutline />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        onClick={() =>
                          setShowText({ ...showText, confirmpassword: true })
                        }
                      >
                        <EyeOffOutline />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                  {formikPassword.errors.confirmNewPassword && (
                    <span className="text-danger">
                      {formikPassword.errors.confirmNewPassword}
                    </span>
                  )}
                </Form.Group>

                <Button
                  disabled={isPasswordLoading}
                  variant="primary"
                  className="my-3"
                  type="submit"
                >
                  {isPasswordLoading ? "Please wait…" : "Change password"}
                </Button>
              </Form>
            )}

            {/* {activeKey === "signature" && (
              <div className="my-3 profile-signature">
                <label className="p-cursor">
                  {!newsignaturePics ? (
                    <img
                      style={{
                        width: "12rem",
                        height: "12rem",
                      }}
                      alt=""
                      src={`${backendUrl}/api/users/signature/${
                        authUser.Staff_ID
                      }`}
                    />
                  ) : (
                    <img
                      style={{
                        width: "12rem",
                        height: "12rem",
                      }}
                      alt=""
                      src={URL.createObjectURL(newsignaturePics)}
                    />
                  )}
                  <input
                    type="file"
                    onChange={(e) =>
                      setnewSignaturePics(() => e.target.files[0])
                    }
                    className="d-none"
                  />
                </label>
                <div className="mt-2 fw-bold">Signature</div>
                <div className="mt-5 d-flex">
                  <button
                    disabled={isLoading}
                    onClick={sendPics}
                    className="btn btn-primary"
                  >
                    {isLoading ? "Please wait…" : "Save Signature"}
                  </button>

                  <button
                    onClick={() => setnewSignaturePics("")}
                    className="btn btn-secondary mx-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            className=""
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

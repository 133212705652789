import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertToBaseCurrency,
  copyText,
  customerFullName,
  maxTopPopperConfig,
  nanoid,
  pcsToTons,
  qtyFormat,
  reactSelectTheme,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
  Units,
  waitFor,
} from "./../utils/helpers";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "./Icons";
import NewCustomerModal from "./NewCustomerModal";
import NewItemModal from "./NewItemModal";
import PageHeader from "./PageHeader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ItemsTable from "./utils/ItemsTable";
import "./../assets/scss/create-invoice.scss";

import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase } from "lodash";

import { useMemo } from "react";
import NumberCustomInput from "./utils/NumberCustomInput";
import { services } from "./../config";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { queryActions, reportActions } from "./../utils/reactQueryActions";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "./SelectBankDialog";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useUpdateEffect,
} from "./../utils/hooks";

import ConfirmDialog from "./ConfirmDialogue";

import { useAuth } from "./../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import printJS from "print-js";
import CustomerSelectModal from "./CustomerSelectModal";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CreatableSelect from "react-select/creatable";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import AddAccounts from "./modals/AddAccounts";
import eventBus from "../utils/EventBus";
import CogSyncOutlineIcon from "mdi-react/CogSyncOutlineIcon";
import { IsPrivileged } from "./DisplayChildElement";
import { ListIcon } from "./Store/StoreIcons";
import ExpenseSettings from "./utils/ExpenseSettings";

const EditableRow = ({
  expense,
  handleRemove,
  index,
  edit,
  currencySymbol,
  canRemoveExpense,
  handleSelectedExpenseAll
}) => {
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const initialValues = {
    Account: "",
    AccountTitle: "",
    Particulers: "",
    Amount: "",
    Ref: "",
    Type: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => { },
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = () => {
    //  console.log(expense);
    formik.setValues({
      Account: expense.AccountID,
      AccountTitle: expense.Description,
      Particulers: expense.Particulers,
      Amount: expense.Amount,
      Ref: expense.Ref,
      Type: expense.Type,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  useEffect(() => {
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    edit({
      index,
      formValues,
    });
  }, [formValues]);

  const handleSelectedExpense = (expense) => {
    formik.setValues({
      ...formik.values,
      Account: expense.AccountID,
      AccountTitle: expense.Description,
      Type: expense.Type,
    });
    setShowExpensesPopover(false);
  };

  return (
    <tr>
      <td>
        {canRemoveExpense && (
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVeritcalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item as="button" onClick={() => handleRemove(index)}>
                <DeleteIcon />
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        )}
      </td>
      <td>
        <InputGroup className="flex-nowrap">
          {index === 0 ? <InputGroup.Text onClick={() => handleSelectedExpenseAll({
            ...formik.values
          })}>
            <CogSyncOutlineIcon />
          </InputGroup.Text> : null}
          <Form.Control
            name="Account"
            value={formik.values.Account}
            onChange={formik.handleChange}
          />
          <Popover
            isOpen={showExpensesPopover}
            onClickOutside={() => setShowExpensesPopover(false)}
            content={() => (
              <ExpensesSelector Type="EXPENSES" handleSelectedExpense={handleSelectedExpense} />
            )}
            position="bottom"
          >
            <InputGroup.Text onClick={() => setShowExpensesPopover(true)}>
              <MagnifyIcon />
            </InputGroup.Text>
          </Popover>
        </InputGroup>
      </td>
      <td>
        <Form.Control
          name="AccountTitle"
          value={formik.values.AccountTitle}
          onChange={formik.handleChange}
        />
      </td>
      <td>
        <Form.Control
          className="h-auto col-6 table-textarea"
          as="textarea"
          name="Particulers"
          value={formik.values.Particulers}
          onChange={formik.handleChange}
          rows={4}
          placeholder="Enter Particulers..."
        />
      </td>
      <td>
        <CurrencyCustomInput
          currencySymbol={currencySymbol}
          name="Amount"
          value={formik.values.Amount}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
      <td>
        <Form.Control
          name="Ref"
          value={formik.values.Ref}
          onChange={formik.handleChange}
          isInvalid={formik.touched.Ref && !!formik.errors.Ref}
        />
      </td>
    </tr>
  );
};

export default function ExpensesEntry() {
  useEffectOnce(() => scrollToTop());
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    },
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
  ];

  const cashOptions = [
    {
      label: "Cash On Hand",
      value: "Cash on hand",
    },
  ];

  const approvedbyOptions = [
    {
      label: "MD",
      value: "MD",
    },
    {
      label: "General Manager",
      value: "General Manager",
    },
    {
      label: "Admin Manager",
      value: "Admin Manager",
    },
  ];

  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl } = useAuth();
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showJournalPopover, setShowJournalPopover] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [canAddExpense, setCanAddExpense] = useState(true);
  const [canRemoveExpense, setCanRemoveExpense] = useState(true);

  useEffectOnce(() => {
    if (location?.state) {
      console.log(location.state);
      if (location.state?.expenses) {
        setTableData(location.state.expenses);
      }

      if (location.state?.job) {
        formik.setFieldValue("jobAccountID", location.state?.job?.jobNumber);
        formik.setFieldValue(
          "jobAccountDescription",
          location.state?.job?.title
        );
      }

      if (location.state?.Vendor_ID) {
        formik.setFieldValue("payTo", location.state?.Vendor_ID);
      }

      if (location.state?.requisitionApprovedBy) {
        formik.setFieldValue(
          "requisitionApprovedBy",
          location.state?.requisitionApprovedBy
        );
        setCanAddExpense(false);
        setCanRemoveExpense(false);
      }

      if (location.state?.requisitionTitle) {
        formik.setFieldValue(
          "requisitionTitle",
          location.state?.requisitionTitle
        );
      }

      if (location.state?.currency) {
        formik.setFieldValue("currency", location.state?.currency);
      }
      if (location.state?.requestid) {
        formik.setFieldValue("requestid", location.state?.requestid);
      }

      if (location.state?.paymentType) {
        formik.setFieldValue(
          "requisitioNpaymentType",
          location.state?.paymentType
        );

        formik.setFieldValue("PaymentType", "None");
        formik.setFieldValue("From", "None");
      }

      navigate(location.pathname, { replace: true });
    }
  });

  const postExpense = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
      //  console.log(payload);
    }

    // return;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/transaction/expense-entry`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postExpenseMutation = useMutation((payload) => postExpense(payload), {
    onSuccess: ({ data, message }) => {
      toast.success(message);
      setTableData([]);
      formik.resetForm();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      PaymentType: "",
      From: "",
      chequeNumber: "",
      payTo: "",
      Remark: "",
      Comment: "",
      Branch: "General",
      ApprovedBy: "",
      Ref: "",
      salesDate: moment(),
      jobAccountID: "",
      jobAccountDescription: "",
      requisitionApprovedBy: "",
      requisitionTitle: "",
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
      requestid: "",
      paymentSources: [
        /*   {
          PaymentType: "",
          From: "",
          chequeNumber: "",
        }, */
      ],
      Amount: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      From: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      if (isEmpty(tableData)) return toast.error(`Please select`);

      const requiresApproval =
        generalSettings.expenseRequiresAdminApproval && !isAdmin;

      const foundRef = tableData.find((el) => el.Ref);
      if (isEmpty(foundRef) && !values.Ref) {
        return formik.setFieldError("Ref", "required");
      }

      let hasError = false;
      let infoText = "";

      // console.log(values)

      if (values?.requisitioNpaymentType !== "Advance") {
        if (!values.Amount) {
          hasError = true;
          toast.error("Enter Amount");
          formik.setFieldError("Amount", "required");
        }

        if (!isEmpty(values.paymentSources)) {
          for (const [i, v] of values.paymentSources.entries()) {
            infoText =
              infoText + `,\n <b>${v.PaymentType}</b> from <b>${v.From}</b>`;

            if (!v.PaymentType) {
              formik.setFieldError(
                `paymentSources[${i}].PaymentType`,
                "Required"
              );
              hasError = true;
            }
            if (!v.From) {
              formik.setFieldError(`paymentSources[${i}].From`, "Required");
              hasError = true;
            }
          }
        }
      }

      if (hasError) return;

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description:
            values?.From !== "None"
              ? `Are you sure, you want to post this expense by \n <b>${values?.PaymentType
              }</b> from <b>${values?.From}</b> ${infoText} ${requiresApproval
                ? "\n Expenses will be sent for approval"
                : ""
              } ${values?.requisitionTitle}`
              : `${values?.requisitionTitle}`,
        })
      ) {
        values.ApprovedBy = values?.requisitionApprovedBy
          ? values.requisitionApprovedBy
          : values.ApprovedBy;

        postExpenseMutation.mutate({
          expenses: tableData.map((el) => {
            const {
              // Deprecated
              /*  Amount,
               paymentSources,
               From,
               PaymentType, */
              ...otherValues
            } = values;
            return {
              ...el,
              ...otherValues,
              Ref: el.Ref ? el.Ref : values.Ref,
            };
          }),
          requiresApproval,
          total,
          From: values?.From,
          Vendor_ID: values?.payTo,
          jobAccountID: values.jobAccountID,
          jobAccountDescription: values.jobAccountDescription,
          payTo: values?.payTo,
          Comment: values.Comment,
          requisitionTitle: values.requisitionTitle,
          conversionAmount: values.conversionAmount,
          currency: values.currency,
          Ref: values?.Ref ? values.Ref : foundRef.Ref,
          requestid: values.requestid,
          ...(values?.requisitioNpaymentType !== "Advance"
            ? {
              paymentSources: [
                {
                  PaymentType: values.PaymentType,
                  From: values.From,
                  chequeNumber: values.chequeNumber,
                  conversionAmount: values.conversionAmount,
                  currency: values.currency,
                  salesDate: values.salesDate,
                  Ref: values?.Ref ? values.Ref : foundRef.Ref,
                  Amount: values.Amount,
                  Remark: values.Remark,
                },
                ...values.paymentSources,
              ].map((el) => ({
                ...el,
                conversionAmount: values.conversionAmount,
                currency: values.currency,
                salesDate: values.salesDate,
                Ref: values?.Ref ? values.Ref : foundRef.Ref,
                Remark: values.Remark,
              })),
            }
            : {}),
          Remark: values.Remark,
        });
      }
    },
    onReset: () => { },
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    if (!isEmpty(data?.imprest)) {
      //  formik.setFieldValue("From", data.imprest[0].value);
    }
    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const handleSelectedExpense = (expense) => {
    expense.Ref = "";
    setTableData([...tableData, expense]);
    setShowExpensesPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedExpense(account);
  });

  const edit = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const handleRemove = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleCreateVendor = (newVendor) => {
    queryClient.setQueryData([queryActions.INVOICE_SETUP], (data) => {
      data.vendors = [...data.vendors, { label: newVendor, value: newVendor }];
      return data;
    });
    formik.setFieldValue("payTo", newVendor);
  };

  const handleCreateBranch = (newBranch) => {
    queryClient.setQueryData([queryActions.INVOICE_SETUP], (data) => {
      data.branch = [...data.branch, { label: newBranch, value: newBranch }];
      return data;
    });
    formik.setFieldValue("Branch", newBranch);
  };

  const resolveFromOptions = (PaymentType) => {
    // console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
        PaymentType
      )
        ? data.banks.filter((el) => el?.currency === formik.values?.currency)
        : cashOptions;
  };

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    formik.setFieldValue("From", "");
    /* if (value === "Imprest") {
      formik.setFieldValue("From", data.imprest[0].value);
    } else if (value === "Cash") {
      formik.setFieldValue("From", cashOptions[0].value);
    } else if (
      ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(value)
    ) {
      formik.setFieldValue("From", data.banks[0].value);
    } */
  };

  const total = useMemo(() => {
    return tableData
      .map((el) => el.Amount)
      .reduce((a, b) => currency(a).add(b).value, 0);
  }, [tableData]);

  const handleSelectedJobAccount = (account) => {
    formik.setFieldValue("jobAccountID", account?.AccountID);
    formik.setFieldValue("jobAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  const totalFromSplit = useMemo(
    () =>
      formik.values.paymentSources
        .map((el) => el.Amount)
        .reduce((a, b) => currency(a).add(b).value, formik.values.Amount),
    [formik.values.paymentSources, formik.values.Amount]
  );

  const debouncedTotal = useDebounce(total, 800);
  useEffect(() => {
    if (isEmpty(formik.values.paymentSources)) {
      formik.setFieldValue('Amount', debouncedTotal)
    }
  }, [debouncedTotal, formik.values.paymentSources,]);

  const handleSelectedExpenseAll = async (expense) => {
    if (await ConfirmDialog({
      title: `Use ${expense.AccountTitle} Account for all entries`,
      description: `Are you sure ?`,
    })
    ) {
      const modifiedRecords = [...tableData.map(el => ({
        ...el,
        Account: tableData[0].Account,
        AccountTitle: tableData[0].AccountID,
        Type: tableData[0].Type,
        Particulers: el.Particulers,
        Amount: el.Amount,
        Ref: el.Ref,
      }))]
      console.log(modifiedRecords)
      setTableData([])
      await waitFor(50)
      setTableData(modifiedRecords)
    };
  }

  return (
    <IsPrivileged roleName={["Expenses Entry"]}>
      <main className="create-invoice">
        <PageHeader
          name="Expenses Entry"
          description="Bill Payment"
          icon={<CreateInvoiceIcon />}
        />
        <div className="p-3 content">
          <div className="d-md-flex content-holder rounded">
            <section
              /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
              className="item-details"
            >
              <div>
                {/*  <header>
                <h1>Bill Payment</h1>
              </header> */}
                <div className="actions">
                  <div>
                    <h1 className="h4">Bill Payment</h1>
                  </div>
                  <div className="d-flex gap-3">
                    <Form.Group>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select Currency"
                        isSearchable={false}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value === formik.values.currency
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("currency", value)
                        }
                        isDisabled={!canAddExpense}
                      />
                    </Form.Group>

                    {formik.values.currency &&
                      formik.values.currency !==
                      generalSettings?.prevailingCurrency ? (
                      <Form.Group>
                        <CurrencyCustomInput
                          currencySymbol={"Ex. Rate"}
                          name="conversionAmount"
                          value={formik.values.conversionAmount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    ) : null}

                    {canAddExpense && <> <Link to={"/expenses-entry-bulk"} className="btn btn-outline-primary" style={{ lineHeight: 2 }}> Multi Mode </Link><ExpenseSettings mode={'Single Mode'} /></>}
                  </div>
                </div>

                <div className="selected-data-area">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table text-nowrap with-large-textarea"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Account</th>
                          <th>Account Title</th>
                          <th>Particulers</th>
                          <th>Amount</th>
                          <th>Reciept/Ref</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((el, index) => (
                          <React.Fragment key={index}>
                            <EditableRow
                              index={index}
                              expense={el}
                              handleRemove={handleRemove}
                              edit={edit}
                              currencySymbol={currencySymbol}
                              canRemoveExpense={canRemoveExpense}
                              handleSelectedExpenseAll={handleSelectedExpenseAll}
                            />
                          </React.Fragment>
                        ))}
                        {canAddExpense && (
                          <tr style={{ backgroundColor: "#F7f7f7" }}>
                            <td colSpan={6}>
                              <Popover
                                isOpen={showExpensesPopover}
                                onClickOutside={() =>
                                  setShowExpensesPopover(false)
                                }
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={
                                      handleSelectedExpense
                                    }
                                    onHide={() => setShowExpensesPopover(false)}
                                  //   type="EXPENSES"
                                  />
                                )}
                                position="bottom"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="px-3"
                                  onClick={() => setShowExpensesPopover(true)}
                                >
                                  + Add
                                </Button>
                              </Popover>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected an item yet</h2>
                        <p>
                          You can click <DotsHorizontalIcon /> to add an item to
                          the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end total-info">
                <table className="table table-borderless balance">
                  <tbody>
                    {!isEmpty(formik.values.paymentSources) && (
                      <tr>
                        <td>Total from split</td>
                        <td>
                          {currency(totalFromSplit, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Total</td>
                      <td>
                        {currency(total, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="customer">
              <FormikProvider value={formik}>
                <Form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <section className="date">
                    <h2 className="mb-4">Payment Details</h2>

                    <Form.Group className="form-mb align-items-center">
                      <Form.Group className="mb-3">
                        <Form.Label>Job Account</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            name="account"
                            placeholder="Select Drum Account"
                            value={`${formik.values?.jobAccountID ||
                              "..."} / ${formik.values?.jobAccountDescription ||
                              "..."}`}
                            onChange={() => { }}
                            readOnly
                          />

                          <Popover
                            isOpen={showJournalPopover}
                            onClickOutside={() => setShowJournalPopover(false)}
                            content={() => (
                              <ExpensesSelector
                                handleSelectedExpense={handleSelectedJobAccount}
                                usage={"chart of accounts"}
                                DetailType="JOB"
                              // onHide={() => setShowJournalPopover(false)}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() => setShowJournalPopover(true)}
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Label>Pay to the order of</Form.Label>
                      <CreatableSelect
                        classNamePrefix="form-select"
                        options={data.vendors}
                        value={data.vendors.find(
                          (el) => el.value === formik.values.payTo
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("payTo", value)
                        }
                        isSearchable
                        onCreateOption={handleCreateVendor}
                      />
                    </Form.Group>

                    <Form.Group className="form-mb align-items-center">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="Remark"
                        value={formik.values.Remark}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </section>

                  <Row className="form-mb">
                    <Form.Group as={Col}>
                      <Form.Label>Sales Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        dateFormat="MMM DD, YYYY"
                        name="salesDate"
                        inputProps={{
                          className: `date-input form-control ${formik.touched.salesDate &&
                            !!formik.errors.salesDate
                            ? "is-invalid"
                            : ""
                            }`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.salesDate}
                        onChange={(date) => {
                          formik.setFieldValue("salesDate", date, true);
                        }}
                        onBlur={() => formik.setFieldTouched("salesDate", true)}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Reciept/Ref</Form.Label>
                      <InputGroup
                        isInvalid={formik.touched.Ref && !!formik.errors.Ref}
                      >
                        <Form.Control
                          name="Ref"
                          value={formik.values.Ref}
                          onChange={formik.handleChange}
                          isInvalid={formik.touched.Ref && !!formik.errors.Ref}
                        />
                        <InputGroup.Text
                          title="Generate"
                          onClick={() =>
                            formik.setFieldValue("Ref", nanoid(10, "number"))
                          }
                        >
                          <CogSyncOutlineIcon />
                        </InputGroup.Text>
                      </InputGroup>

                      <Form.Control.Feedback type="invalid">
                        {formik.errors.Ref}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  {formik.values?.requisitioNpaymentType !== "Advance" && (
                    <>
                      <Row className="form-mb">
                        <Form.Group as={Col}>
                          <Form.Label>By</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPlacement="top"
                            placeholder="Choose method"
                            isSearchable={false}
                            key={formik.values?.PaymentType}
                            value={paymentMethod.find(
                              (el) => el.value === formik.values.PaymentType
                            )}
                            options={paymentMethod}
                            onChange={({ value }) => handlePaymentMethod(value)}
                            getOptionLabel={(el) => (
                              <div className="label-with-icon d-flex gap-2 align-items-center">
                                <span>{el.icon}</span>{" "}
                                <span className="fw-5">{el.label}</span>
                              </div>
                            )}
                          //  menuIsOpen={true}
                          />
                        </Form.Group>

                        <Form.Group as={Col}>
                          <Form.Label>From</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            value={resolveFromOptions(
                              formik.values.PaymentType
                            ).find((el) => el.value === formik.values.From)}
                            options={resolveFromOptions(
                              formik.values.PaymentType
                            )}
                            onChange={({ value }) => {
                              formik.setFieldValue("From", value)
                            }}
                            key={formik.values?.PaymentType}
                          />
                          {formik.touched.From && !!formik.errors.From ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.From}
                            </span>
                          ) : null}
                        </Form.Group>
                      </Row>

                      <Form.Group className="form-mb">
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name={`Amount`}
                          value={formik.values.Amount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                        <ErrorMessage
                          component="span"
                          className="text-danger mb-2"
                          name={`Amount`}
                        />
                      </Form.Group>

                      {formik.values.PaymentType === "Cheque" && (
                        <Form.Group className="form-mb align-items-center">
                          <Form.Label className="mb-1 text-nowrap fw-bold">
                            Cheque No.
                          </Form.Label>
                          <Form.Control
                            name="chequeNumber"
                            value={formik.values.chequeNumber}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}

                      {/* <hr />

                      <FieldArray
                        name="paymentSources"
                        render={(arrayHelpers) => (
                          <>
                            <div>
                              {formik.values.paymentSources.map((el, index) => (
                                <>
                                  <Row className="form-mb">
                                    <Form.Group className="col-6">
                                      <Form.Label className="d-none">
                                        By
                                      </Form.Label>
                                      <Select
                                        classNamePrefix="form-select"
                                        menuPlacement="top"
                                        placeholder="Choose method"
                                        isSearchable={false}
                                        value={paymentMethod.find(
                                          (el) =>
                                            el.value ===
                                            formik.values.paymentSources[index]
                                              .PaymentType
                                        )}
                                        options={paymentMethod}
                                        onChange={({ value }) => {
                                          formik.setFieldValue(
                                            `paymentSources[${index}].PaymentType`,
                                            value
                                          );
                                          formik.setFieldValue(
                                            `paymentSources[${index}].From`,
                                            ""
                                          );
                                        }}
                                        getOptionLabel={(el) => (
                                          <div className="label-with-icon d-flex gap-2 align-items-center">
                                            <span>{el.icon}</span>{" "}
                                            <span className="fw-5">
                                              {el.label}
                                            </span>
                                          </div>
                                        )}
                                      />
                                      <ErrorMessage
                                        component="span"
                                        className="text-danger mb-2"
                                        name={`paymentSources[${index}].PaymentType`}
                                      />
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                      <Form.Label className="d-none">
                                        From
                                      </Form.Label>
                                      <Select
                                        classNamePrefix="form-select"
                                        value={resolveFromOptions(
                                          formik.values.paymentSources[index]
                                            .PaymentType
                                        ).find(
                                          (el) =>
                                            el.value ===
                                            formik.values.paymentSources[index]
                                              .From
                                        )}
                                        options={resolveFromOptions(
                                          formik.values.paymentSources[index]
                                            .PaymentType
                                        )}
                                        onChange={({ value }) =>
                                          formik.setFieldValue(
                                            `paymentSources[${index}].From`,
                                            value
                                          )
                                        }
                                        key={
                                          formik.values.paymentSources[index]
                                            ?.PaymentType
                                        }
                                      />
                                      <ErrorMessage
                                        component="span"
                                        className="text-danger mb-2"
                                        name={`paymentSources[${index}].From`}
                                      />
                                    </Form.Group>

                                    <Button
                                      className="col-1"
                                      title="Remove"
                                      variant=""
                                      type="button"
                                      size="xs"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      ✖
                                    </Button>
                                  </Row>

                                  <Form.Group className="form-mb col-10">
                                    <CurrencyCustomInput
                                      currencySymbol={currencySymbol}
                                      name={`paymentSources[${index}].Amount`}
                                      value={
                                        formik.values.paymentSources[index]
                                          .Amount
                                      }
                                      onValueChange={(value, name) => {
                                        formik.setFieldValue(name, value);
                                      }}
                                      placeholder="0.00"
                                    />
                                  </Form.Group>

                                  {formik.values.paymentSources[index]
                                    .PaymentType === "Cheque" && (
                                      <Form.Group className="form-mb align-items-center">
                                        <Form.Label className="mb-1 text-nowrap fw-bold">
                                          Cheque No.
                                        </Form.Label>
                                        <Form.Control
                                          name={`paymentSources[${index}].chequeNumber`}
                                          value={
                                            formik.values.paymentSources[index]
                                              .chequeNumber
                                          }
                                          onChange={formik.handleChange}
                                        />
                                      </Form.Group>
                                    )}
                                  <hr />
                                </>
                              ))}
                            </div>
                            <div className="d-flex justify-content-start px-4">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary text-nowrap"
                                onClick={() =>
                                  arrayHelpers.push({
                                    PaymentType: "",
                                    From: "",
                                    chequeNumber: "",
                                    Amount: 0,
                                  })
                                }
                              >
                                + Add
                              </button>
                            </div>
                          </>
                        )}
                      /> */}
                    </>
                  )}

                  <hr />

                  <Row className="form-mb">
                    <Form.Group as={Col}>
                      <Form.Label>Branch/Dept</Form.Label>
                      <CreatableSelect
                        classNamePrefix="form-select"
                        options={data?.branch}
                        value={data?.branch?.find(
                          (el) => el.value === formik.values.Branch
                        )}
                        isSearchable
                        onCreateOption={handleCreateBranch}
                        onChange={({ value }) =>
                          formik.setFieldValue("Branch", value)
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Approved By</Form.Label>

                      {formik.values?.requisitionApprovedBy ? (
                        <Form.Control
                          value={formik.values?.requisitionApprovedBy}
                          onChange={() => { }}
                          readOnly
                        />
                      ) : (
                        <Select
                          classNamePrefix="form-select"
                          options={approvedbyOptions}
                          onChange={({ value }) =>
                            formik.setFieldValue("ApprovedBy", value)
                          }
                          value={approvedbyOptions.find(
                            (el) => el.value === formik.values.ApprovedBy
                          )}
                        />
                      )}
                    </Form.Group>
                  </Row>

                  <Form.Group className="form-mb align-items-center">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Comment
                    </Form.Label>
                    <Form.Control
                      name="Comment"
                      value={formik.values.Comment}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <section className="buttons">
                    <Button
                      type="button"
                      variant="outline-primary"
                      // className="border-0"
                      onClick={() => discard()}
                    >
                      Refresh
                    </Button>
                    <Button type="submit" variant="primary">
                      Post Now
                    </Button>
                  </section>
                </Form>
              </FormikProvider>
            </section>
          </div>
        </div>

        {/*   Modals */}

        {/*  {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={setSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}



      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
        />
      )}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

    */}

        <ModalLoader show={postExpenseMutation.isLoading || isLoading} />
      </main>
    </IsPrivileged>
  );
}

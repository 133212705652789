import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import NumberCustomInput from "./utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import ItemSizeDialog from "./ItemSizeDialog";
import currency from "currency.js";
import moment from "moment";
import { toast } from "react-toastify";
import { convertToBaseCurrency, nanoid } from "../utils/helpers";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import { FilesAttachments } from "../utils/Attachments";
import { useCurrencies } from "../utils/hooks";
import { cloneDeep } from "lodash";
import { durationOptions } from "../config";

const Units = [
  {
    label: "Tons",
    value: "Tons",
  },
  {
    label: "Pieces",
    value: "Pieces",
  },
];

const usageOptions = [
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Office Use",
    value: "Office Use",
  },
  {
    label: "Promo",
    value: "Promo",
  },
];

export default function NewItemServiceModal({
  showCreateNewInventoryModal,
  setShowCreateNewInventoryModal,
  setItemType,
}) {
  const { backendUrl, token } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const queryClient = useQueryClient();
  const [images, setImages] = useState([]);
  const {
    deploymentCurrencies: currenciesOptions,
    getCurrencySymbol,
  } = useCurrencies();

  const setUp = async (department) => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = data?.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      Vendor: "",
      Item_Type: "Service",
      Bar_Code: nanoid(6, "number"),
      Item_Name: "",
      InvoiceNo: "",
      Size: "",
      Unit: "Each",
      Usage: "Sales",
      UnitPrice: "0",
      Freight: "0",
      UnitCost: "0",
      Quantity: "1",
      ManDate: moment(),
      ExpireDate: moment(),
      Date_Log: moment(),
      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",
      Reorder_Level: "",
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
      showInStore: false,
      ItemStatus: "Processed",
      dueInDurationValue: "Month",
      dueIn: 3,
    },
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Service Code is required"),
      Item_Name: yup.string().required("Service Name is required"),
      // InvoiceNo: yup.string().required("Invoice number is required"),
      // Product_Name: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      //  Vendor: yup.string().required(),
      UnitPrice: yup.string().required(),
      Quantity: yup.string().required(),
    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      values.vendorName = data?.vendors.find(
        (el) => el.value === values.Vendor
      )?.label;
      /*   values.InvoiceAmount = InvoiceAmount;
      values.UnitCost = UnitCost; */
      values.ManDate = values.Date_Log;
      values.ExpireDate = values.Date_Log;

      const payload = {
        newItem: values,
        vendorName: values.vendorName,
        purchaseHistory: null,
      };
      if (
        payload.newItem.currency &&
        payload.newItem.currency !== generalSettings?.prevailingCurrency
      ) {
        payload.newItem = convertToBaseCurrency({
          data: payload.newItem,
          conversionAmount: payload.newItem.conversionAmount,
        });
      }

      const formData = new FormData();
      formData.append("payload", JSON.stringify(payload));

      for (const file of images) {
        formData.append("files", file);
      }

      createMutation.mutate(formData);
    },
    onReset: () => { },
  });

  const createItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/add-stock`, {
      method: "POST",
      credentials: "include",
      body: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createMutation = useMutation((payload) => createItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      setShowCreateNewInventoryModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  return (
    <>
      <Modal
        show={showCreateNewInventoryModal}
        onHide={() => setShowCreateNewInventoryModal(false)}
        dialogClassName="new-entity-modal new-service-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Add New Product Information</h1>
            <p>Add a new item by filling in the following forms.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className="row new-item-form w-100 m-0"
            autoComplete="off"
          >
            <div className="col-md-6 px-4">
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-3">Item Type</Form.Label>
                <div className="d-flex gap-3 justify-content-between w-50">
                  <Form.Check
                    inline
                    label="Inventory"
                    value="Inventory"
                    name="Item_Type"
                    type="radio"
                    defaultChecked={false}
                    onClick={(e) => setItemType(e.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Service"
                    value="Service"
                    name="Item_Type"
                    type="radio"
                    checked={true}
                  />
                </div>
              </Form.Group>

              <Form.Label className="mb-2">Currency</Form.Label>
              <div className="d-flex gap-2  mb-3 pb-2">
                <Form.Group>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select Currency"
                    isSearchable={false}
                    options={currenciesOptions}
                    value={currenciesOptions.find(
                      (el) => el.value === formik.values.currency
                    )}
                    onChange={({ value }) => {
                      formik.setFieldValue("currency", value);
                    }}
                  />
                </Form.Group>
                {formik.values.currency &&
                  formik.values.currency !==
                  generalSettings?.prevailingCurrency ? (
                  <Form.Group>
                    <CurrencyCustomInput
                      currencySymbol={"Ex. Rate"}
                      name="conversionAmount"
                      value={formik.values.conversionAmount}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      placeholder="0.00"
                    />
                  </Form.Group>
                ) : null}
              </div>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Service Code</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Service Code"
                  name="Bar_Code"
                  value={formik.values.Bar_Code}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Bar_Code && !!formik.errors.Bar_Code
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Bar_Code}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Service Name</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Service Name"
                  name="Item_Name"
                  value={formik.values.Item_Name}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Item_Name && !!formik.errors.Item_Name
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Item_Name}
                </Form.Control.Feedback>
              </Form.Group>

              {/*  <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Product</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.products}
                value={data?.products?.find(
                  (el) => el.value === formik.values.Product_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Product_Name", value)
                }
              />
              {formik.touched.Product_Name && formik.errors.Product_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Product_Name}
                </span>
              ) : null}
            </Form.Group> */}

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Category</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.categories}
                  value={data?.categories?.find(
                    (el) => el.value === formik.values.Cat_Name
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Cat_Name", value)
                  }
                />
                {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Cat_Name}
                  </span>
                ) : null}
              </Form.Group>
            </div>

            <div className="col-md-6 px-4">
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1 text-nowrap">
                  Service Charge
                </Form.Label>
                <CurrencyCustomInput
                  currencySymbol={getCurrencySymbol(formik.values.currency)}
                  name="UnitPrice"
                  placeholder="0.00"
                  value={formik.values.UnitPrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onBlur={() => formik.setFieldTouched("UnitPrice", true)}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1 text-nowrap">ROI</Form.Label>
                <CurrencyCustomInput
                  currencySymbol={getCurrencySymbol(formik.values.currency)}
                  name="ROI"
                  placeholder="0.00"
                  value={formik.values.ROI}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.ROI && !!formik.errors.ROI}
                  onBlur={() => formik.setFieldTouched("ROI", true)}
                />
                {formik.touched.ROI && !!formik.errors.ROI ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.ROI}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1 text-nowrap">Expected Return</Form.Label>
                <CurrencyCustomInput
                  currencySymbol={getCurrencySymbol(formik.values.currency)}
                  name="Expected_Return"
                  placeholder="0.00"
                  value={currency(formik.values.ROI).add(formik.values.UnitPrice).value}
                  onValueChange={(value, name) => {

                  }}
                  readOnly={true}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Duration</Form.Label>
                <Row>
                  <Col>
                    <NumberCustomInput
                      placeholder="0"
                      name="dueIn"
                      value={formik.values.dueIn}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                    />
                  </Col>
                  <Col>
                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={""}
                      value={durationOptions.find(
                        (el) => el.value === formik.values.dueInDurationValue
                      )}
                      options={durationOptions}
                      onChange={({ value }) =>
                        formik.setFieldValue("dueInDurationValue", value)
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>

              {/*  <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={data.vendors}
                value={data?.categories?.find(
                  (el) => el.value === formik.values.Vendor
                )}
                onChange={({ value }) => formik.setFieldValue("Vendor", value)}
              />
              {formik.touched.Vendor && formik.errors.Vendor ? (
                <span className="text-danger mt-2">{formik.errors.Vendor}</span>
              ) : null}
            </Form.Group> */}

              {/*    <div className="row text-nowrap align-items-center">
              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={Units}
                  value={Units.find((el) => el.value === formik.values.Unit)}
                  onChange={({ value }) => formik.setFieldValue("Unit", value)}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>

              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Size</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data?.itemMeasurement}
                  value={data?.itemMeasurement.find(
                    (el) => el.value === formik.values.Size
                  )}
                  onChange={({ value }) => formik.setFieldValue("Size", value)}
                />
              </Form.Group>

              <Form.Group className="col-2 pb-2 px-0">
                <Button size="sm" onClick={() => addItemMeasurement()}>
                  + Add
                </Button>
              </Form.Group>
            </div> */}

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Usage</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={usageOptions}
                  value={usageOptions.find(
                    (el) => el.value === formik.values.Usage
                  )}
                  onChange={({ value }) => formik.setFieldValue("Usage", value)}
                />
              </Form.Group>
              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Branch</Form.Label>
                  <Form.Control defaultValue={formik.values.Branch} readOnly />
                </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Post Date</Form.Label>
                  <DateTime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="MMM DD, YYYY"
                    inputProps={{
                      className: `date-input form-control`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.Date_Log}
                    onChange={(date) => {
                      formik.setFieldValue("Date_Log", date, true);
                    }}
                  />
                </Form.Group>
              </div>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Check
                  type="switch"
                  label="Show in store"
                  name="showInStore"
                  className="custom-form-check mb-3"
                  checked={formik.values.showInStore}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              <Form.Group className="form-mb align-items-center mb-3">
                <Form.Label>Images</Form.Label>
                <FilesAttachments
                  files={images}
                  setFiles={(files) => setImages(files)}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            type="submit"
            className="w-50 submit-btn mt-3"
          >
            Send To Inventory
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={createMutation.isLoading} />
    </>
  );
}

/* export default function NewItemModal({
  showCreateNewInventoryModal,
  setShowCreateNewInventoryModal,
}) {
  return (
    <Modal
      show={showCreateNewInventoryModal}
      onHide={() => setShowCreateNewInventoryModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add New Item</h1>
          <p>Add a new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="row new-item-form" autoComplete="off">
          <div className="col-md-6">
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter your username"
                name="username"
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter your username"
                name="username"
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Manufacturer</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Branch</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Warehouse</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
          </div>

          <div className="col-md-6">
            <Form.Group className="mb-3">
              <Form.Label className="mb-2 invisible">{"..."}</Form.Label>
              <div className="d-flex gap-3 justify-content-between w-50">
                <Form.Check
                  inline
                  label="Inventory"
                  value="Inventory"
                  name="TransType"
                  type="radio"
                />
                <Form.Check
                  inline
                  label="Service"
                  value="Service"
                  name="TransType"
                  type="radio"
                />
              </div>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Storage Location</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Description</Form.Label>
              <Form.Control
                className=""
                as="textarea"
                placeholder=""
                name="username"
                rows={5}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Unit Cost</Form.Label>
              <CurrencyCustomInput placeholder="0.00" />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Unit Price</Form.Label>
              <CurrencyCustomInput placeholder="0.00" />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Status</Form.Label>
              <Select classNamePrefix={`form-select`} />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>QTY</Form.Label>

              <div className="duration">
                <NumberCustomInput placeholder="0" name="qty" />

                <Select
                  classNamePrefix={`form-select`}
                  placeholder={"Month"}
                  options={[
                    {
                      value: "Day",
                      label: "Day",
                    },
                    {
                      value: "Week",
                      label: "Week",
                    },
                    {
                      value: "Month",
                      label: "Month",
                    },
                  ]}
                />
              </div>
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          variant="primary"
          type="submit"
          className="w-50 submit-btn mt-3"
        >
          Send To Inventory
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
 */

import { Table, Dropdown, Form, InputGroup } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useMutation, useQuery } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
// import { CSVLink } from "react-csv";
// import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
// import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { ExchangeFunds, EditIcon, DeleteIcon, ViewIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import {
  fetchActionsUtil,
  paginationOptions,
  scrollToTop,
} from "../../utils/helpers";
import AddEditFarmlandModal from "../modals/AddEditFarmland";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ShowStorageItems from "../utils/ShowStorageItems";
import ReactPaginate from "react-paginate";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { IsPrivileged } from "../DisplayChildElement";

function FarmlandPage() {
  useScrollTop();
  const initialFilterParams = {
    startDate: "",
    endDate: "",
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [pickedStorage, setPickedStorage] = useState(false);
  const [openItems, setOpenItems] = useState(false);
  const [StorageLocation, setStorageLocation] = useState("");
  const [deletefarmland, setDeletefarmland] = useState("");
  const [openDeleteCat, setOpenDeleteCat] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((query) => ({
      ...query,
      ...debouncedFilterParams,
    }));
  }, [debouncedFilterParams, setQueryParams]);

  const { data, refetch, isFetching } = useQuery(
    ["FARMLAND-ITEMS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/items/farmland?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const deleteStorageLocation = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/items/farmland/${payload}`, "DELETE"),
    {
      onSuccess: () => {
        toast.success("Farmland deleted Successfully");
        refetch();
      },
      onError: ({ message }) => {
        //console.log(message);
        setDeletefarmland(message);
        setOpenDeleteCat(true);
        toast.error("This Farmland is attached to items", {
          position: "top-right",
        });
      },
    }
  );

  const deleteStorageLocationAction = async (farmland) => {
    if (
      await ConfirmDialog({
        title: `Delete Farmland`,
        description: `Are you sure you want to DELETE this farmland `,
      })
    ) {
      deleteStorageLocation.mutate(farmland);
    }
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <IsPrivileged roleName={["Farmland"]}>
      <main className="cash-book table-sticky-header">
        <div className="content">
          <header className="container">
            <h1>
              Farmlands
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>

            <div className="actions mr-5">
              <div>
                <InputGroup>
                  <Form.Control
                    name="name"
                    placeholder="search..."
                    value={filterParams?.name}
                    onChange={(e) => handleFilterParamsChange(e)}
                  />
                  <InputGroup.Text>
                    <MagnifyIcon />
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="position-relative">
                <RsDateRangePicker
                  placement="bottomEnd"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                        parse(
                          filterParams.startDate,
                          "yyyy-MM-dd",
                          new Date()
                        ),
                        parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>

              <button
                onClick={() => setOpenCreateModal(true)}
                className="btn bg-primary text-white"
              >
                Create New <ExchangeFunds color="white" />
              </button>
            </div>
          </header>

          <div>
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
            >
              <thead className="position-sticky top-0">
                <tr>
                  <th />
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {data?.items?.map((ek, i) => (
                  <tr key={i}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item
                            as="div"
                            className="p-cursor"
                            onClick={() => {
                              setStorageLocation(ek);
                              setOpenItems(true);
                            }}
                          >
                            <ViewIcon /> View Items
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="div"
                            className="p-cursor"
                            onClick={() => {
                              setPickedStorage(ek);
                              setOpenEditModal(true);
                            }}
                          >
                            <EditIcon /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="div"
                            onClick={() => {
                              deleteStorageLocationAction(ek.farmland);
                            }}
                            className="p-cursor"
                          >
                            <DeleteIcon color="#94A3B8" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>{i + 1}</td>
                    <td>{ek?.farmland || "..."}</td>
                    <td>{ek.date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {data?.items?.length < 1 && <NoTableItem queryParams={queryParams} />}
          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <div className="pagination_left">
              <p className="m-0 p-0">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select"
                onChange={(e) => handleSearchQueryChange(e)}
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="40">40 rows</option>
                <option value="50">50 rows</option>
                <option value="100">100 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data?.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                scrollToTop();
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </div>
        <ModalLoader show={isFetching} />
        {openCreateModal && (
          <AddEditFarmlandModal
            show={openCreateModal}
            onHide={() => setOpenCreateModal(false)}
            refetch={refetch}
          />
        )}
        {openEditModal && (
          <AddEditFarmlandModal
            show={openEditModal}
            onHide={() => setOpenEditModal(false)}
            refetch={refetch}
            editStorage={true}
            pickedStorage={pickedStorage}
          />
        )}

        {openItems && (
          <ShowStorageItems
            show={openItems}
            onHide={() => setOpenItems(false)}
            farmland={StorageLocation}
            ShowName={`${StorageLocation?.farmland} Farmland`}
          />
        )}
        {openDeleteCat && (
          <AddEditFarmlandModal
            show={openDeleteCat}
            onHide={() => setOpenDeleteCat(false)}
            refetch={refetch}
            deletefarmland={deletefarmland}
            Farmlands={data?.items?.map((el) => ({
              label: el?.farmland,
              value: el?.farmland,
            }))}
          />
        )}
      </main>
    </IsPrivileged>
  );
}

export default FarmlandPage;

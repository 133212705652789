import currency from "currency.js";
import { useQuery } from "react-query";
import { useNavigate, Outlet } from "react-router-dom";
import { BucketIcon, PageHeaderListIcon } from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { useIsAdmin } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";
import { formatForQty } from "../../utils/helpers";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { getQueryParams } from "../../config";

export function WarehouseWrapper({ Nav }) {
  const { backendUrl, user } = useAuth();
  const isAdmin = useIsAdmin();

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  let navigate = useNavigate();

  const {
    page = 1,
    limit = 40,
    barcode = "",
    itemName = "",
    product = "",
    category = "",
    branch = "",
    startDate,
    endDate,
  } = getQueryParams();

  const getStats = async (id) => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/stats/${id}?category=${category}&product=${product}&page=${page}$limit=${limit}&barcode=${barcode}&itemName=${itemName}&branch=${branch}&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let data = await response.json();

    return data;
  };

  const {
    data = { totalInventory: [], warehouseUsers: [], warehouse: {} },
    isFetching,
  } = useQuery(
    [
      "ITEMS_WAREHOUSE",
      {
        wareshouseLocal,
        category,
        product,
        barcode,
        itemName,
        page,
        limit,
        branch,
        startDate,
        endDate,
      },
    ],
    () => getStats(wareshouseLocal?.whID),
    {
      onSuccess: (data) => { },
    }
  );

  const checkUsers = useCallback(() => {
    const users = data?.warehouseUsers?.map((el) => el?.Staff_ID);
    if (!users.includes(user?.Staff_ID) && !isAdmin) {
      toast.error(
        `You are not registered to ${data?.warehouse?.W_name
        },  Please contact admin.`
      );
      navigate("/warehouse/warehouse");
    }
  }, [
    data?.warehouse?.W_name,
    data?.warehouseUsers,
    isAdmin,
    navigate,
    user?.Staff_ID,
  ]);

  useEffect(() => {
    if (data.warehouseUsers?.length > 0) {
      checkUsers();
    }
  }, [data.warehouseUsers, checkUsers]);

  return (
    <div className="invoice-wrapper ">
      <section className={`stats mb-3 two-column `}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <BucketIcon />
          </div>
          {isFetching ? (
            <div className="text-center">
              <p>Loading...</p>
            </div>
          ) : (
            <div className="details">
              <p>
                {currency(
                  data?.totalInventory?.length > 0
                    ? data?.totalInventory[0]?.Quantity
                    : 0,
                  {
                    symbol: "",
                    precision: 2,
                    format: formatForQty,
                  }
                ).format()}
              </p>
              <span>Total Inventory</span>
            </div>
          )}
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <PageHeaderListIcon />
          </div>
          {isFetching ? (
            <div className="text-center">
              <p>Loading...</p>
            </div>
          ) : (
            <div className="details">
              <p>
                {currency(
                  data?.totalInventory?.length > 0
                    ? data?.totalInventory[0]?.valueInStock
                    : 0,
                  {
                    symbol: "",
                  }
                ).format()}
              </p>
              <span>Value In Stock</span>
            </div>
          )}
        </div>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}

import { Badge, Button, Dropdown, Form } from "react-bootstrap";
import "../../assets/scss/store/StoreNavBar.scss";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { appSettings, backendApis } from "../../config";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import Avatar from "../utils/Avatar";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Fragment, useRef } from "react";
import Select from "react-select";
import { RegisterCustomer } from "../CustomerDetails/Register";
import { LoginCustomer } from "../CustomerDetails/Login";
import { useAuth } from "../../hooks/useAuth";
import {
  fetchActionsUtil,
  formatForQty,
  initialGeneralSettings,
} from "../../utils/helpers";
import { CartIcon, LocationIcon, PhoneIcon } from "./StoreIcons";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import { LinkWithQuery } from "../utils/LinkWithQuery";
import currency from "currency.js";
import useDebounce, {
  useEffectOnce,
  useOnClickOutside,
  useToggle,
} from "../../utils/hooks";
import eventBus from "../../utils/EventBus";
import { truncate } from "lodash";
import { HamburgerIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import { Notification } from "../Notification";
import { SendPasswordResetLink } from "../SendPasswordResetLinkModal";
import { isEmpty } from "lodash";
import { useCacheBuster } from "react-cache-buster";

function ProfileArea({ }) {
  const navigate = useNavigate();
  const { checkCacheStatus } = useCacheBuster();

  const [searchParams, setSearchParams] = useSearchParams();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const totalInCart = useStoreState((state) =>
    state.cart
      .map((el) => el.Quantity)
      .reduce((a, b) => currency(a).add(b).value, 0)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [
    showSendPasswordResetLinkModal,
    setShowsSendPasswordResetLinkModal,
  ] = useState(false);
  const {
    logoutCustomer,
    customerBackendUrl: backendUrl,
    customer,
  } = useAuth();

  const logOut = async () => {
    try {
      setIsLoading(true);
      logoutCustomer();
      await fetchActionsUtil(`${backendUrl}/api/customers/logout`, "GET");
      navigate(appSettings.storeBaseUrl, { replace: true });
      window.location.reload();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    const action = searchParams.get("action");
    if (action === "login" && isEmpty(customer)) {
    }
  });

  return (
    <>
      <div className="d-flex profile-area">
        <Link
          variant=""
          className="btn"
          to={`${appSettings.storeBaseUrl}/cart`}
        >
          <CartIcon />
          <Badge bg="danger">
            {currency(totalInCart, {
              symbol: "",
              precision: 2,
              format: formatForQty,
            }).format()}
          </Badge>
        </Link>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="42"
          viewBox="0 0 2 42"
          fill="none"
        >
          <path d="M1 0V42" stroke="#E2E8F0" />
        </svg>

        <div className="d-flex">
          {customer && (
            <div className="mx-3 p-1">
              <Notification
                backendUrl={backendUrl}
                Cust_ID={customer?.Cust_ID}
              />
            </div>
          )}

          <Dropdown>
            <Dropdown.Toggle
              variant="white"
              className="btn d-flex border-0 align-items-center p-1"
            >
              <Avatar
                className="avatar"
                name={customer?.FirstName}
                userId={customer?.Cust_ID}
                Cust_ID={customer?.Cust_ID}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
            >
              {customer && (
                <>
                  <Dropdown.Item as="div">
                    <Link to={`${appSettings.storeBaseUrl}/account/profile`}>
                      Profile
                    </Link>
                  </Dropdown.Item>
                  {generalSettings?.customerCanEditPriceInCart && (
                    <Dropdown.Item as="div">
                      <Link to={`${appSettings.storeBaseUrl}/account/quotes`}>
                        Quotes
                      </Link>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item as="div">
                    <Link to={`${appSettings.storeBaseUrl}/account/orders`}>
                      Orders
                    </Link>
                  </Dropdown.Item>
                </>
              )}
              {!customer && (
                <Dropdown.Item
                  as="div"
                  className="p-cursor"
                  onClick={() => setShowLogin(true)}
                >
                  Login
                </Dropdown.Item>
              )}
              {!customer && (
                <Dropdown.Item
                  as="div"
                  className="p-cursor"
                  onClick={() => setShowRegister(true)}
                >
                  Register
                </Dropdown.Item>
              )}
              <Dropdown.Item as="div">
                <Link to={`${appSettings.storeBaseUrl}/track-order`}>
                  Track Order
                </Link>
              </Dropdown.Item>
              {!customer && (
                <Dropdown.Item
                  as="div"
                  className="p-cursor"
                  onClick={() => setShowsSendPasswordResetLinkModal(true)}
                >
                  Forgot Password
                </Dropdown.Item>
              )}
              {customer && (
                <Dropdown.Item as="div" className="p-cursor" onClick={logOut}>
                  Log Out
                </Dropdown.Item>
              )}
              <Dropdown.Item as="button" onClick={checkCacheStatus}>
                Version {process.env?.REACT_APP_VERSION}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {showLogin && (
        <LoginCustomer show={showLogin} onHide={() => setShowLogin(false)} />
      )}
      {showRegister && (
        <RegisterCustomer
          show={showRegister}
          onHide={() => setShowRegister(false)}
          createdCustomer={() => {
            setShowLogin(true);
          }}
        />
      )}

      {showSendPasswordResetLinkModal && (
        <SendPasswordResetLink
          backendUrl={backendApis.find((el) => el?.isStore)?.url}
          showSendPasswordResetLinkModal={showSendPasswordResetLinkModal}
          setShowsSendPasswordResetLinkModal={
            setShowsSendPasswordResetLinkModal
          }
          company={backendApis.find((el) => el?.isStore)?.name}
          usage="store"
        />
      )}
      <ModalLoader show={isLoading} />
    </>
  );
}

export default function StoreNavBar() {
  const location = useLocation();
  const navHolder = useRef();
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const { q = "" } = useSearchParams();
  const [storeSearchString, setStoreSearchString] = useState(q);
  const debouncedStoreSearchString = useDebounce(storeSearchString, 500);

  useEffect(() => {
    eventBus.dispatch("STORE_SEARCH_STRING", debouncedStoreSearchString);
  }, [debouncedStoreSearchString]);

  const {
    logoutCustomer,
    customerBackendUrl: backendUrl,
    customer,
  } = useAuth();

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/store/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
      to: el.Product_Name,
      name: el.Product_Name,
    }));
    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
      to: el.Cat_Name,
      name: el.Cat_Name,
    }));

    setItemMeasurements(data.itemMeasurements);
    setGeneralSettings({ ...initialGeneralSettings, ...data.settings });
    document.title = data?.settings?.storeName;
    return data;
  };

  const {
    data = { categories: [], products: [], company: {}, settings: {} },
  } = useQuery(["STORE_NAV_SET_UP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const navItems = useMemo(
    () => [
      {
        name: "Home",
        to: `${appSettings.storeBaseUrl}/home`,
      },
      { name: "Categories", to: "categories", childRoutes: data?.categories },
      { name: "Manufacturer", to: "products", childRoutes: data?.products },
      ...(customer
        ? [
          {
            name: "Customer Products",
            to: `${appSettings.storeBaseUrl}/customer-products`,
          },
        ]
        : []),
      { name: "Contact Us", to: `${appSettings.storeBaseUrl}/contact-us` },
    ],
    [data?.products, data?.categories, customer]
  );

  return (
    <nav className="storeNavBar">
      <div className="top">
        <div className="d-flex">
          <NavLink to={appSettings.storeBaseUrl} className="logo-area">
            <img
              src={`${backendUrl}/images/company-logo.png`}
              width="50"
            /*    height="26"   */
            /*  style={{ width: "8rem" }} */
            />
            <h1>{data?.settings?.storeName || "Store"}</h1>
          </NavLink>

          <div className="action d-none d-md-flex">
            <Button variant="white">
              <PhoneIcon />{" "}
              {truncate(data?.company?.Phone || "0000000000", {
                length: 15,
              })}
            </Button>
            {/* <Dropdown>
              <Dropdown.Toggle variant="white" bsPrefix={``}>
                <LocationIcon />
                Location
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
              >
                {backendApis.map((el) => (
                  <Dropdown.Item
                    key={el.name}
                    as="button"
                       className={`${authUser?.company === el.name ? "active" : ""}`}
                    //  onClick={() => switchCompany(el)}
                  >
                    {el.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>

          <div className="mx-3 d-none d-md-flex align-items-center search-parent">
            <div className="global-search-area">
              <MagnifyIcon />
              <Form.Control
                name="q"
                value={storeSearchString}
                onChange={(e) => setStoreSearchString(e.target.value)}
                placeholder="Search Item..."
              />
            </div>{" "}
            {/* <Select
              placeholder="All Category"
              classNamePrefix={"form-select"}
            /> */}
          </div>
        </div>
        <div className="d-none d-md-flex">
          <ProfileArea />
        </div>
      </div>

      <div className="bottom">
        {" "}
        <ul ref={navHolder} className={navOpen ? "open" : "closed"}>
          {navItems.map((item, index) => (
            <li key={index}>
              {!item.childRoutes ? (
                <Link
                  to={item?.to ? item.to : ""}
                  onClick={() => toggleNavOpen()}
                >
                  {item.name}
                </Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="white">{item.name}</Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {item.childRoutes.map((el, index) => (
                      <Fragment key={index}>
                        {el.to ? (
                          <Dropdown.Item as="div">
                            <LinkWithQuery
                              to={`${appSettings.storeBaseUrl}${item.to ? `/${item.to}` : ""
                                }/${el.to}`}
                              onClick={() => toggleNavOpen()}
                            >
                              {el.name}
                            </LinkWithQuery>
                          </Dropdown.Item>
                        ) : el.onClick ? (
                          <Dropdown.Item
                            onClick={() => el.onClick()}
                            as="button"
                          >
                            {el.name}{" "}
                            <span className="shortcut">{el?.shortcut}</span>
                          </Dropdown.Item>
                        ) : el.grandchildRoutes ? (
                          <div className="position-relative submenu">
                            <button
                              className="dropdown-item justify-content-between submenu-toggle px-3"
                              href="#"
                            >
                              {el.name} <span>&raquo;</span>
                            </button>
                            {el.grandchildRoutes && (
                              <ul className="dropdown-menu dropdown-submenu text-left">
                                {el.grandchildRoutes.map((grandEl) => (
                                  <li className="w-100" key={grandEl.name}>
                                    <Dropdown.Item as="div">
                                      <Link to={`/${grandEl.to}`}>
                                        {grandEl.name}
                                      </Link>
                                    </Dropdown.Item>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </li>
          ))}
        </ul>{" "}
        <div className="d-flex d-md-none justify-content-between align-items-center gap-3 w-100 p-3 px-0">
          <button onClick={() => toggleNavOpen()} className="btn p-0 d-md-none">
            <HamburgerIcon />
          </button>
          <div className="d-flex align-items-center search-parent">
            <div className="global-search-area">
              <MagnifyIcon />
              <Form.Control
                name="q"
                value={storeSearchString}
                onChange={(e) => setStoreSearchString(e.target.value)}
                placeholder="Search Item..."
              />
            </div>
          </div>

          <ProfileArea />
        </div>
      </div>
    </nav>
  );
}

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "../utils/confirm";
import { ApproveModalIcon, BookIcon, RecieptIcon } from "./Icons";
import { Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useBackendUrl, useCustomerBackendUrl } from "../utils/hooks";
import ReceiptDialogAddon from "./ReceiptDialogAddon";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  TransactionID,
  contractTransactionID,
}) {
  const backendUrl = useBackendUrl();

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <section className="row flex-wrap gap-4 mx-3 mt-4">
          <Dropdown className="w-100 px-0">
            <Dropdown.Toggle variant="primary" className="w-100 py-3">
              Open Receipt
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-with-icons">
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}?thermalPrinter=false`}
                target="blank"
              >
                <RecieptIcon />
                A4 Size Printer
              </Dropdown.Item>
              <Dropdown.Item
                href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}?thermalPrinter=true`}
                target="blank"
              >
                <RecieptIcon />
                Thermal Printer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {contractTransactionID && (
            <>
              <a
                href={`${backendUrl}/api/invoice/pdf/contract/${TransactionID}?thermalPrinter=true`}
                target="blank"
                className="btn btn-light-blue p-3 w-100"
              >
                Open Contract
              </a>

              {/* <ReceiptDialogAddon TransactionID={TransactionID} /> */}
            </>
          )}
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default function ReceiptDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}

import { Dropdown, Form, Button, Table, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// import MultiRangeSlider from "multi-range-slider-react";

import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
// import { queryActions } from "../../utils/reactQueryActions";
import {
  copyLink,
  formatNumberSystem,
  getDomainOrigin,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NewVendorModal from "../NewVendorModal";
import EditVendorModal from "./EditVendor";
import {
  FilterTwoIcon,
  FilterCollapseIcon,
  MessageIcon,
  UserIcon,
  PDFIcon,
  ExcelIcon,
  ExportIcon,
  DeleteIcon,
  CoinIcon,
  LinkVariantIcon,
} from "../Icons";
import MultiRangeSlider from "../MultiRangeSlider";
import { fetchActionsUtil } from "../../utils/helpers";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import {
  ApproveCheckIcon,
  EditIcon,
  ChartPieSliceIcon,
  GearIcon,
} from "../Icons";
import VendorProducts from "./VendorProducts";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { IsPrivileged } from "../DisplayChildElement";
import { Divider } from "rsuite";
import { VendorOpeningBalance } from "./VendorOpeningBalance";
import { useStoreState } from "easy-peasy";
import NewRequisitionModal from "../Requisition/NewRequisition";
import { encryptData } from "../../utils/EncryptDecrpting";
import { Popover } from "react-tiny-popover";
import VendorSelector from "./VendorSelector";

export default function Vendors() {
  const { backendUrl, token } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showEditVendorModal, setShowEditVendorModal] = useState({
    open: false,
    id: "",
  });

  const [max, setMax] = useState();
  const [min, setMin] = useState();

  const [jobsValue, setJobsValue] = useState([]);
  const [certValue, setCertValue] = useState([]);

  const [initialRanges, setInitialRanges] = useState({ max: 0, min: 0 });
  const [showItemsPopover, setShowItemsPopover] = useState(false);

  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedVendor, setSelectedvendor] = useState(null);
  const [
    selectedVendorOpeningBalance,
    setSelectedvendorOpeningBalance,
  ] = useState(null);
  const initialFilterParams = {
    q: "",
    cert: "",
    job: "",
    maxRange: 0,
    minRange: 0,
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const getCustomer = async (debouncedqueryParams) => {
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/vendors?${queryString.stringify(
        debouncedqueryParams
      )}`,
      "GET",
      token
    );

    const vendorData = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-data`,
      "GET"
    );

    setInitialRanges({
      max: vendorData?.maxRange,
      min: vendorData?.minRange,
    });

    data.vendorData = vendorData;

    data.states = [
      {
        label: "All",
        value: "",
      },
      ...data.states
        .map((el) => ({
          label: el.state,
          value: el.state,
        }))
        .filter((el) => el.value),
    ];

    data.lgas = [
      {
        label: "All",
        value: "",
      },
      ...data.lgas
        .map((el) => ({
          label: el.LGA,
          value: el.LGA,
        }))
        .filter((el) => el.value),
    ];

    return data;
  };

  const { data = { vendors: [], vendorData: {} }, refetch } = useQuery(
    ["GET all the vendors", debouncedFilterParams],
    () => getCustomer(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const setSelectedVendor = (e) => {
    if (Object.entries(e).length > 0) {
      refetch();
    }
  };

  const setEditVendor = (e) => {
    if (e) {
      refetch();
    }
  };

  // const onSelected = (userId) => {
  //   const theSelected = [...selectedData];
  //   if (theSelected.length > 0) {
  //     const index = theSelected.findIndex((i) => i === userId);

  //     if (index >= 0) {
  //       theSelected.splice(index, 1);
  //       setSelectedData(theSelected);
  //     } else {
  //       setSelectedData((d) => [...d, userId]);
  //     }
  //   } else {
  //     setSelectedData((d) => [...d, userId]);
  //   }
  // };

  // const sendRFQ = () => {
  //   console.log(selectedData);
  // };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
    setJobsValue([]);
    setCertValue([]);
  };

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      maxRange: max,
      minRange: min,
    });
  }, [min, max]);

  const verifyVendorMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/verify-user/${payload}`,
        "POST",
        token,
        {
          maincompany: backendUrl,
        }
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ error, message }) => {
        console.log(error);
        toast.error(message);
      },
    }
  );

  const verifyVendor = async (/* id, name, verified */ vendor) => {
    if (!vendor.Vendor_ID) return toast.error("Vendor Id is needed");

    // check if requires permision
    const requiresPermission =
      generalSettings.vendorVerificationRequiresApproval && !isAdmin;

    if (requiresPermission) {
      let payload = { maincompany: backendUrl };
      setShowNewRequisitionModal({
        vendor,
        title: `Verify Vendor: ${vendor.CompanyName}`,
        actionDetails: {
          payload,
          action: "VERIFY VENDOR",
          fetchUrl: `${backendUrl}/api/vendors/verify-user/${vendor.Vendor_ID}`,
          fetchOptions: {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
          },
        },
      });
      return;
    }

    if (
      await ConfirmDialog({
        title:
          vendor?.verified !== "true"
            ? "Verify Vendor"
            : "Resend Registration Email",
        description: `Are you sure you want to ${vendor?.verified !== "true"
          ? "verify"
          : "resend registration email to"
          } ${vendor.CompanyName}`,
      })
    ) {
      verifyVendorMutation.mutate(vendor.Vendor_ID);
    }
  };

  const searchSelect = (name, value) => {
    const el = value.map((el) => el.value);
    if (name === "job") setJobsValue(value);
    if (name === "cert") setCertValue(value);

    setFilterParams({
      ...filterParams,
      [name]: el,
    });
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/vendors?${queryString.stringify(rest)}`,
      "GET",
      token
    );

    const company = exData.data?.company?.CompName;

    // console.log(exData);

    exData = exData?.data?.vendors?.map((el, index) => [
      index + 1,
      formatNumberSystem(el?.numberPrefix, el?.currentNumber),
      el.CompanyName,
      el.ContactName,
      el.AccountNumber,
      el.Bank,
      el.DateCreated,
      el.Email,
      el?.Vendor_jobs?.map((jb) => `${jb?.name}, `),
      el?.verified === "true" ? "Verified" : "Not Verified",
      el.verifiedBy,
      el.Phone,
      el.Vendor_ID,
      el.City,
      el.Address,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : `${format(new Date(), "E MMM d yyyy k:mm:ss z")}`;
    exData = [
      [company],
      ["Vendors Report"],
      [date],
      [""],
      [
        "S/N",
        "#",
        "Company Name",
        "Contact Name",
        "Account Number",
        "Bank",
        "Date Created",
        "Email",
        "Services/Products",
        "Status",
        "Verified By",
        "Phone",
        "Vendor_ID",
        "City",
        "Address",
      ],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  // console.log("filterParams?.job", filterParams?.job);

  const deleteVendor = async (payload) => {
    let response = await fetch(`${backendUrl}/api/vendors/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteVendorMutation = useMutation((payload) => deleteVendor(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handledeleteVendor = async (vendor) => {
    // check if requires permision
    const requiresPermission =
      generalSettings.vendorDeleteRequiresApproval && !isAdmin;

    if (requiresPermission) {
      let payload = vendor;
      setShowNewRequisitionModal({
        vendor,
        title: `Delete Vendor: ${vendor.CompanyName}`,
        actionDetails: {
          payload,
          action: "DELETE VENDOR",
          fetchUrl: `${backendUrl}/api/vendors/delete`,
          fetchOptions: {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
          },
        },
      });
      return;
    }

    if (
      await ConfirmDialog({
        title: "Delete Vendor",
        description: `Are you sure, you want to delete ${vendor.CompanyName}`,
      })
    ) {
      deleteVendorMutation.mutate(vendor);
    }
  };

  async function handleDeactivate(el) {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/vendors/activate-deactivate-vendor`,
      "POST",
      "",
      { isDeactivate: el?.isDeactivate, Vendor_ID: el?.Vendor_ID }
    );
    refetch();
    toast.success(res.message);
  }

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      // inorder not to change the backend
      Vendor_ID: customer.Vendor_ID,
      customerId: customer.Vendor_ID,
      customerName: customer.CompanyName,
      q: customer.CompanyName,
    });
    setShowItemsPopover(false);
  };

  return (
    <IsPrivileged roleName={["Vendors", "vendor"]}>
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      {/* <div className="input-group mb-3">
                        <span className="input-group-text border-right-0 bg-white">
                          <MagnifyIcon />
                        </span>
                        <Form.Control
                          id="queryParams-q"
                          className=""
                          name="q"
                          value={filterParams?.q}
                          onChange={(e) => handleFilterParamsChange(e)}
                          placeholder="Search by name"
                        />
                      </div> */}

                      <Form.Group className="mb-2-5">
                        <Form.Label>Vendor ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="customerId"
                            placeholder="Enter Vendor ID"
                            value={filterParams?.customerId}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <VendorSelector
                                handleSelectedCustomer={handleSelectedCustomer}
                                setShowCustomerSelector={setShowItemsPopover}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>


                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Search By Status</b>
                        </Form.Label>
                        <select
                          className="form-select form-select-md"
                          name="status"
                          aria-label="Default select example"
                          onChange={(e) => handleFilterParamsChange(e)}
                          value={filterParams?.status}
                        >
                          <option value="">Select...</option>
                          {[
                            { label: "Verified", value: "true" },
                            { label: "Not Verified", value: "false" },
                          ]?.map(({ value, label }, i) => (
                            <option key={i} value={value}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Search by Certificate</b>
                        </Form.Label>

                        <Select
                          isMulti
                          theme={reactSelectTheme}
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          maxMenuHeight={150}
                          options={data?.vendorData?.vendorCert
                            ?.sort((a, b) =>
                              a.localeCompare(b, undefined, {
                                sensitivity: "base",
                              })
                            )
                            ?.map((el) => ({
                              label: el,
                              value: el,
                            }))}
                          value={certValue}
                          onChange={(value) => {
                            searchSelect("cert", value);
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                      // style={{ zIndex: 99999, position: "relative" }}
                      >
                        <Form.Label>
                          <b>Search By Product/Service</b>
                        </Form.Label>
                        <Select
                          // menuPosition="fixed"
                          theme={reactSelectTheme}
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          maxMenuHeight={200}
                          isMulti
                          options={data?.vendorData?.vendorJobs
                            ?.sort((a, b) =>
                              a.localeCompare(b, undefined, {
                                sensitivity: "base",
                              })
                            )
                            ?.map((el) => ({
                              label: el,
                              value: el,
                            }))}
                          value={jobsValue}
                          onChange={(value) => {
                            searchSelect("job", value);
                          }}
                        />
                      </Form.Group>

                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Price Range"
                          name="enablePriceRange"
                          type={"checkbox"}
                          id={`enablePriceRange`}
                          defaultChecked={JSON.parse(
                            filterParams?.enablePriceRange || false
                          )}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div>

                      {initialRanges.min > 0 || initialRanges.max > 0 ? (
                        <Form.Group className="my-3">
                          <Form.Label>
                            <b>Search By Range</b>
                          </Form.Label>
                          <MultiRangeSlider
                            min={initialRanges.min}
                            max={initialRanges.max}
                            onChange={(e) => {
                              setMax(e.max);
                              setMin(e.min);
                            }}
                            formatNumber={true}
                          />
                        </Form.Group>
                      ) : null}

                      <hr />

                      <Form.Group className="mb-2-5">
                        <Form.Label>State</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              state: selected.value,
                              LGA: "",
                            });
                          }}
                          defaultValue={data?.states?.find(
                            (el) => el.value === queryParams?.state
                          )}
                          options={data?.states}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>City</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              LGA: selected.value,
                            });
                          }}
                          value={data?.lgas?.find(
                            (el) => el.value === queryParams?.LGA
                          )}
                          options={data?.lgas}
                        />
                      </Form.Group>

                      {/* <hr className="mt-3 mb-4" /> */}
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="content-body">
                <header className="text-nowrap">
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}

                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-wrap gap-3">
                        <Button
                          onClick={() => refetch()}
                          variant=""
                          className="bg-light-blue border-0 text-primary"
                        >
                          <CachedIcon />
                        </Button>
                      </div>
                    </div>
                  </h1>
                  <div className="fw-bold">
                    <h6>{filterParams?.customerName}</h6>
                  </div>
                  <div className="actions">
                    {/* {selectedData.length > 0 && (
                    <Button
                      disabled={selectedData.length > 0 ? false : true}
                      variant="primary mx-4"
                      onClick={sendRFQ}
                    >
                      Send RFQ
                    </Button>
                  )} */}

                    <CSVLink
                      className="btn print d-none"
                      filename={`Vendors Report(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        {/* <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/items/pdf/inventory-entry?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button
                      variant="light-blue"
                      onClick={() =>
                        copyLink({
                          link: `${getDomainOrigin()}/vendor-registration?q=${encryptData(
                            backendUrl
                          )}`,
                          toast: toast,
                        })
                      }
                    >
                      <LinkVariantIcon /> Registration Link
                    </Button>

                    <Button
                      variant="light-blue"
                      onClick={() =>
                        copyLink({
                          link: `${getDomainOrigin()}/vendor-login?q=${encryptData(
                            backendUrl
                          )}`,
                          toast: toast,
                        })
                      }
                    >
                      <LinkVariantIcon /> login Link
                    </Button>

                    <Button
                      variant="primary"
                      onClick={() => setShowCreateNewCustomerModal(true)}
                    >
                      + Create New
                    </Button>

                  </div>
                </header>

                <div className="px-4 pb-4">
                  <Table
                    borderless
                    striped
                    responsive
                    className="product-table"
                  >
                    <thead className="sticky border-bottom">
                      <tr>
                        <th />
                        {/* <th>S/N</th> */}
                        <th className="d-none">#</th>
                        <th>Vendor_ID</th>
                        <th>Company Name</th>
                        <th>Contact Name</th>
                        <th>Account Number</th>
                        <th>Bank</th>
                        <th>Date Created</th>
                        <th>Email</th>
                        <th>Services/Products</th>
                        <th>Status</th>
                        <th>Verified By</th>
                        <th>Phone</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Address</th>
                      </tr>
                    </thead>

                    <tbody className="blue-hover">
                      {data.vendors &&
                        data.vendors?.map((el, index) => (
                          <tr key={index}>
                            {/* <td>
                            <input
                              type="checkbox"
                              onChange={() => onSelected(el.Vendor_ID)}
                              checked={selectedData.find(
                                (id) => id === el.Vendor_ID
                              )}
                            />
                          </td> */}
                            <td>
                              <Dropdown alignRight drop="end">
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    onClick={() => verifyVendor(el)}
                                    as="button"
                                  >
                                    {el?.verified !== "true" ? (
                                      <ApproveCheckIcon />
                                    ) : (
                                      <MessageIcon />
                                    )}
                                    {el?.verified !== "true"
                                      ? "Verify Vendor"
                                      : "Resend Email"}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      setShowEditVendorModal({
                                        open: true,
                                        id: el.Vendor_ID,
                                      })
                                    }
                                    as="button"
                                  >
                                    <EditIcon /> Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/vendor/PO?vendorNumber=${el.Vendor_ID
                                        }`}
                                    >
                                      <ChartPieSliceIcon /> Vendor Purchase
                                      Order
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/reports/purchase-history?vendorNumber=${el.Vendor_ID
                                        }&NoDate=${true}&CompName=${el.CompanyName
                                        }`}
                                    >
                                      <ChartPieSliceIcon /> Vendor Supply
                                      History
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <Link
                                      to={`/vendor/vendors-ledger?customerId=${el.Vendor_ID
                                        }&NoDate=${true}&customerName=${el.CompanyName
                                        }`}
                                    >
                                      <GearIcon /> View Vendor Ledger
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedvendor(el);
                                      setShowProductModal(true);
                                    }}
                                    as="button"
                                  >
                                    <UserIcon />
                                    Add Vendor Prices
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() => {
                                      setSelectedvendorOpeningBalance(el);
                                    }}
                                    as="button"
                                  >
                                    <CoinIcon />
                                    Post Opening Balance
                                  </Dropdown.Item>

                                  {isAdmin || isCashier ? (
                                    <>
                                      <Dropdown.Divider />

                                      <div className="d-flex p-cursor">
                                        <p
                                          for="isDeactivate"
                                          className="mx-2 p-cursor"
                                        >
                                          Deactivate Vendor
                                        </p>
                                        <Form.Check
                                          className="mx-3 p-cursor"
                                          type="switch"
                                          id="isDeactivate"
                                          checked={
                                            Boolean(el?.isDeactivate)
                                              ? true
                                              : false
                                          }
                                          onChange={() => handleDeactivate(el)}
                                        />
                                      </div>

                                      <Dropdown.Divider />

                                      <Dropdown.Item
                                        onClick={() => handledeleteVendor(el)}
                                        as="button"
                                        className="text-danger"
                                      >
                                        <DeleteIcon />
                                        Delete
                                      </Dropdown.Item>
                                    </>
                                  ) : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            {/*  <td>{index + 1}</td> */}
                            <td className="text-nowrap d-none">
                              {formatNumberSystem(
                                el?.numberPrefix,
                                el?.currentNumber
                              )}
                            </td>
                            <td>{el.Vendor_ID}</td>
                            <td>
                              <div>{el.CompanyName || "..."}</div>
                              {Boolean(el?.isDeactivate) && (
                                <div className="text-danger">Deactivated</div>
                              )}
                            </td>
                            <td>{el.ContactName || "..."}</td>
                            <td>{el.AccountNumber || "..."}</td>
                            <td>{el.Bank || "..."}</td>
                            <td>{el.DateCreated || "..."}</td>
                            <td>{el.Email || "..."}</td>
                            <td>
                              {el?.Vendor_jobs?.map((jb) => `${jb?.name}, `)}
                            </td>
                            <td className="text-nowrap">
                              <span
                                className={
                                  el?.verified === "true"
                                    ? "view_status_all px-2"
                                    : "view_status_partly px-2"
                                }
                              >
                                {el?.verified === "true"
                                  ? "Verified"
                                  : "Not Verified"}
                              </span>
                            </td>
                            <td>{el.verifiedBy}</td>
                            <td>{el.Phone}</td>
                            {/* <td>{el.City}</td> */}
                            <td>{el.state || "..."}</td>
                            <td>{el.LGA || "..."}</td>
                            <td>{el.Address || "..."}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
                  <div className="pagination_left d-flex gap-3 align-items-center">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
          {showCreateNewCustomerModal && (
            <NewVendorModal
              showCreateNewVendorModal={showCreateNewCustomerModal}
              setShowCreateNewVendorModal={setShowCreateNewCustomerModal}
              setSelectedVendor={setSelectedVendor}
              refetch={refetch}
            />
          )}
          {showEditVendorModal && (
            <EditVendorModal
              setShowCreateNewVendorModal={setShowEditVendorModal}
              showCreateNewVendorModal={showEditVendorModal}
              setSelectedVendor={setEditVendor}
              refetch={refetch}
            />
          )}

          {showProductModal && selectedVendor ? (
            <VendorProducts
              showProductModal={showProductModal}
              setShowProductModal={setShowProductModal}
              selectedVendor={selectedVendor}
              itemRefetch={refetch}
            />
          ) : null}
        </main>
      </section>
      {selectedVendorOpeningBalance && (
        <VendorOpeningBalance
          show={selectedVendorOpeningBalance}
          setShow={setSelectedvendorOpeningBalance}
          vendor={selectedVendorOpeningBalance}
        />
      )}

      {showNewRequisitionModal && (
        <NewRequisitionModal
          modalTitle="Send for Approval"
          defaultFormValues={{
            title: showNewRequisitionModal.title,
            description: showNewRequisitionModal.description,
            type: "User Action (Delete Vendor)",
            cost: 0,
            vendor: `  ${showNewRequisitionModal.vendor.CompanyName} / ${showNewRequisitionModal.vendor.Vendor_ID
              }`,
            loanDetails: JSON.stringify(showNewRequisitionModal?.actionDetails),
          }}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
          handleCreatedRequisition={() => { }}
        />
      )}
    </IsPrivileged>
  );
}

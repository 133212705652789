import { Table, Button, Form, Modal, Row, Dropdown } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import {
  ApproveCheckIcon,
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  GearIcon,
  PrintIcon,
  ViewIcon,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import DateRangePicker from "./utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FileLargeIcon } from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "./../utils/reactQueryActions";
import useDebounce, {
  useEffectOnce,
  useIsAdmin,
  useQueryParams,
  useScrollTop,
} from "./../utils/hooks";
import queryString from "query-string";
import { services } from "./../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  convertMomentDate,
  paginationOptions,
  scrollToTop,
} from "./../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import { useAuth } from "./../hooks/useAuth";
import PageHeader from "./PageHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import LoginDialog from "./LoginDialog";
import ConfirmDialog from "./ConfirmDialogue";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import Datetime from "react-datetime";

import {
  fetchActionsUtil,
  sendFormData,
  statusUpdateClass,
} from "./../utils/helpers";
import ModalLoader from "./utils/ModalLoader";
// import UploadImage from "./utils/UploadImage";
import ViewImageModal from "./ViewImageModal";
import UploadPDF from "./utils/UploadPDF";
import NoTableItem from "./utils/NoTableItem";
import CurrencyInput from "react-currency-input-field";
import PORequisitionModal from "./Requisition/PORequisition";
import UpdateItemModal from "./UpdateItemModal";
import GRNDialog from "./GRNDialog";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import moment from "moment";
import UpdateWarehouseItemModal from "./warehouse/UpdateWarehouseItemModal";
import { useStoreState } from "easy-peasy";
import AddWarehousePOModal from "./AddWarehousePO";

export function SingleRFQTableModal({
  show,
  onHide,
  selectedRFQ,
  disableInput,
  showSelect,
  fetchPO,
  addToInvemtry,
  useRequestid,
  showRFQTableRefresh,
  refetch,
  headerName,
  headerNo,
  supplyDate,
  showSupplyDate,
  dueDate,
  theRFQName,
  usage,
  IsQuoteSent,
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);

  const { backendUrl } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [ShowNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(
    false
  );
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);

  const [showUpdateItemsModal, setShowUpdateItemsModal] = useState(false);

  const [selectedPO, setSelectedPO] = useState({});

  const [DistributedPO, setDistributedPO] = useState([]);

  const [showWarehousePO, setShowWarehousePO] = useState(false);

  const ref = useRef(null);

  const showRFQTable = async (vendor, RFQId, requestid) => {
    const element = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-items?vendor=${vendor}&RFQId=${RFQId}&requestid=${requestid}`,
      "GET"
    );

    const items = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-product/${vendor}?type=${"Stock"}`,
      "GET"
    );

    const newItems = element.data[0]?.items?.map((el) => {
      const amounts = items.vendor?.find((am) => am?.Bar_Code === el?.barcode)
        ?.cost;
      let am = el.amount;
      if (el.amount === 0) {
        am = amounts ? amounts : 0;
      }

      return {
        ...el,
        amount: am,
      };
    });

    setTableData(newItems);
    // setTableData(element.data[0]?.items);
    showSelect && setSelectedData(newItems);
  };

  const showPOTable = async (vendor, RFQId, requestid) => {
    const element = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-po-items?vendor=${vendor}&POId=${RFQId}&requestid=${requestid}`,
      "GET"
    );

    const all = element.data[0]?.items?.map((el) => ({
      ...el,
      supplyDate: el?.supplyDate ? el?.supplyDate : moment(),
      e_dey: el?.supplyDate ? el?.supplyDate : null,
    }));
    setTableData(all);
  };

  const showDistribution = async (vendor, POId, requestid, barcode) => {
    const element = await fetchActionsUtil(
      `${backendUrl}/api/vendors/distribution-po-items?vendor=${vendor}&POId=${POId}&requestid=${requestid}&barcode=${barcode}`,
      "GET"
    );
    // console.log(element?.items);
    setDistributedPO(element?.items);
    setShowWarehousePO(true);
    // const all = element.data[0]?.items?.map((el) => ({
    //   ...el,
    //   supplyDate: el?.supplyDate ? el?.supplyDate : moment(),
    //   e_dey: el?.supplyDate ? el?.supplyDate : null,
    // }));
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  const printWHGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
      useAPI: `${backendUrl}/api/warehouse/pdf/grn`,
    });
  };

  useEffect(() => {
    if (fetchPO) {
      showPOTable(
        selectedRFQ?.vendor,
        selectedRFQ?.POId,
        selectedRFQ?.requestid
      );
    } else if (useRequestid) {
      showRFQTable(
        selectedRFQ?.vendor,
        selectedRFQ?.RFQId,
        selectedRFQ?.requestid
      );
    } else {
      showRFQTable(
        selectedRFQ?.vendor,
        selectedRFQ?.RFQId,
        selectedRFQ?.QuoteId
      );
    }
  }, [selectedRFQ]);

  // console.log(tableData);
  const handleInputChange = ({ index, name, value }) => {
    // console.log({ index, name, value });
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const AddPriceMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/add-quote-price`,
        "post",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);

        if (showRFQTableRefresh) {
          showRFQTableRefresh(
            selectedRFQ?.vendor,
            selectedRFQ?.RFQId,
            selectedRFQ?.requestid
          );
          onHide(false);
        }
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const sendDataUpdate = async () => {
    if (
      await ConfirmDialog({
        title: "Save Price",
        description: `Are you sure you want to save the price`,
      })
    ) {
      AddPriceMutation.mutate({ tableData });
    }
  };

  const onSelected = (item) => {
    const theSelected = [...selectedData];

    if (theSelected?.length > 0) {
      const index = theSelected.findIndex((it) => it.id === item.id);
      const vendorindex = theSelected.findIndex(
        (it) => it.vendor === item.vendor
      );

      if (vendorindex >= 0) {
        if (index >= 0) {
          theSelected.splice(index, 1);
          setSelectedData(theSelected);
        } else {
          setSelectedData((d) => [...d, item]);
        }
      } else {
        setSelectedData([item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData(tableData);
    }
  };

  const approvePOMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/approve-supply-date`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
        onHide(false);
      },
      onError: ({ error = "" }) => {
        toast.error(`Unable to perform action: ${error}`);
      },
    }
  );

  const saveSupplyDate = async () => {
    const newData = tableData.map((el) => ({
      ...el,
      supplyDate: el?.supplyDate.format(),
    }));
    const payload = { POId: selectedRFQ?.POId, newData };
    console.log(payload);

    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventry",
      })
    ) {
      approvePOMutation.mutate(payload);
    }
  };

  // -------------------------------------------------------------

  const qhseStatusActionMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/vendors/change-warehouse-receive-assurance`,
        "post",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        console.log(data);
        setTableData((oldTableData) =>
          oldTableData.map((el) =>
            el.id === data.id
              ? {
                ...el,
                warehouseRceiveAssuranceStatus: data.action,
                warehouseRceiveAssuranceComment: data.comment,
                warehouseRceiveAssuranceDate: data.assuranceDate,
              }
              : { ...el }
          )
        );
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const qhseStatusAction = async ({ action, ...el }) => {
    const { proceed, comment } = await ConfirmDialog({
      title: action,
      description: `Are you sure you want to ${action}`,
      withComment: true,
    });
    if (proceed) {
      qhseStatusActionMutation.mutate({ action, comment, ...el });
    }
  };

  // ---------------------------------------------------------------
  const filterItems = () => {
    const inventryItems = tableData.filter(
      (el) => el?.warehouse === "" || el?.warehouse === " "
    );

    const warehouseItems = tableData.filter(
      (el) => el?.warehouse !== "" && el?.warehouse !== " "
    );
    return { inventryItems, warehouseItems };
  };

  // console.log(tableData);

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className=" w-100">
          <div className="d-flex justify-content-between">
            <div>
              <h1 className="h6">{headerName}</h1>
            </div>
            <div>
              <h1 className="h6 mx-4">{headerNo}</h1>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main className="inner-page-outlet">
          <main className="cash-book p-0">
            <div className="content">
              <div className="row">
                <div className="col-12">
                  <div className="p-3">
                    <div>
                      <Table
                        responsive
                        borderless
                        striped
                        className="product-table"
                      >
                        <thead>
                          <tr>
                            {showSelect && (
                              <th>
                                <input
                                  type="checkbox"
                                  onChange={selectAll}
                                  checked={Boolean(
                                    selectedData?.length === tableData?.length
                                  )}
                                />
                              </th>
                            )}
                            {fetchPO && <th />}
                            <th>S/N</th>
                            <th>Item Code</th>
                            <th>Item Name</th>

                            {generalSettings?.pharmacySetting && (
                              <>
                                <th className="d-none">
                                  Generic Configuration
                                </th>
                                <th className="d-none">Branded Generic</th>
                                <th>Pack Size</th>
                                <th>Tier</th>
                              </>
                            )}

                            <th>Unit Cost</th>
                            {fetchPO ? (
                              <th>Quantity Ordered </th>
                            ) : (
                              <th>Quantity</th>
                            )}
                            {fetchPO && <th>Quantity Received </th>}
                            <th>Subtotal</th>
                            {supplyDate && <th>Supply Date</th>}
                            {showSupplyDate && <th>Supply Date</th>}
                            {generalSettings?.approveInboundPo && (
                              <>
                                {usage === "qhse" ? (
                                  <th>Approval Action</th>
                                ) : (
                                  <th> Approval Status </th>
                                )}
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody ref={ref}>
                          {tableData &&
                            tableData.map((el, index) => (
                              <tr
                                key={index}
                                // className={`${activeTableIndex === index &&
                                //   "active-row"}`}
                                className={`${el?.quantity === el?.receiveQuantity
                                  ? "opacity-50"
                                  : "p-cursor"
                                  }`}
                                onClick={() =>
                                  activeTableIndex !== index &&
                                  setActiveTableIndex(index)
                                }
                              >
                                {showSelect && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      onChange={() => onSelected(el)}
                                      checked={Boolean(
                                        selectedData.find(
                                          (ek) => ek.id === el.id
                                        )
                                      )}
                                    />
                                  </td>
                                )}
                                {fetchPO && (
                                  <td>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant=""
                                        className="bg-white border-0"
                                        bsPrefix="print more"
                                      >
                                        <DotsHorizontalIcon />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        popperConfig={{
                                          strategy: "fixed",
                                        }}
                                        renderOnMount
                                        className="dropdown-with-icons"
                                      >
                                        <Dropdown.Item
                                          as="button"
                                          onClick={() => {
                                            showDistribution(
                                              el?.vendor,
                                              el?.POId,
                                              el?.requestid,
                                              el.barcode
                                            );

                                            setSelectedPO(el);
                                          }}
                                        >
                                          <ViewIcon />
                                          View PO Distribution
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                )}
                                <td>{index + 1}</td>
                                <td className="p-cursor">{el.barcode}</td>
                                <td>{el.itemName}</td>

                                {generalSettings?.pharmacySetting && (
                                  <>
                                    <td className="d-none">
                                      {el?.Generic_Configuration}
                                    </td>
                                    <td className="d-none">
                                      {el?.Branded_Generic}
                                    </td>
                                    <td>{el?.Pack_Size}</td>
                                    <td>{el?.Tier}</td>
                                  </>
                                )}

                                <td>
                                  <CurrencyInput
                                    style={{
                                      minWidth: "7rem",
                                    }}
                                    className="form-control border-0 px-1"
                                    value={el.amount}
                                    onKeyDown={(e) =>
                                      e.keyCode === 13 && e.target.blur()
                                    }
                                    disabled={
                                      disableInput
                                        ? true
                                        : el?.Status === "Pending"
                                          ? true
                                          : false
                                    }
                                    name="amount"
                                    onValueChange={(value, name) =>
                                      handleInputChange({
                                        index,
                                        name,
                                        value,
                                      })
                                    }
                                    //    disableGroupSeparators
                                    decimalsLimit={2}
                                  />
                                </td>
                                <td>{el?.quantity}</td>
                                {fetchPO && (
                                  <td>
                                    {el?.receiveQuantity
                                      ? el?.receiveQuantity
                                      : 0}
                                  </td>
                                )}
                                <td>
                                  <CurrencyInput
                                    style={{
                                      minWidth: "7rem",
                                    }}
                                    className="form-control border-0 px-0"
                                    name="subtotal"
                                    disabled
                                    value={
                                      !isNaN(el.amount * el.quantity)
                                        ? currency(el.amount, {
                                          precision: 2,
                                        }).multiply(el.quantity)
                                        : 0
                                    }
                                    onKeyDown={(e) =>
                                      e.keyCode === 13 && e.target.blur()
                                    }
                                    onValueChange={(value, name) =>
                                      handleInputChange({
                                        index,
                                        name,
                                        value,
                                      })
                                    }
                                    // disableGroupSeparators
                                    decimalsLimit={2}
                                  />
                                </td>
                                {supplyDate && (
                                  <td>
                                    <Popover
                                      reposition={false}
                                      isOpen={
                                        activeTableIndex === index &&
                                        datePopoverOpened
                                      }
                                      onClickOutside={() =>
                                        activeTableIndex === index &&
                                        setDatePopoverOpened(false)
                                      }
                                      align="end"
                                      padding={10}
                                      content={() => (
                                        <Datetime
                                          dateFormat="MM DD, YYYY"
                                          className="date-picker-2"
                                          timeFormat={false}
                                          closeOnSelect={true}
                                          closeOnClickOutside={true}
                                          name="supplyDate"
                                          inputProps={{
                                            className: `date-input form-control`,
                                            placeholder: "Select date",
                                            readOnly: true,
                                          }}
                                          input={false}
                                          value={el.supplyDate}
                                          onChange={(date) => {
                                            handleInputChange({
                                              index,
                                              name: "supplyDate",
                                              value: date,
                                            });

                                            setDatePopoverOpened(false);
                                          }}
                                        />
                                      )}
                                    >
                                      <DatePickerCustomInput
                                        onClick={() =>
                                          setDatePopoverOpened(
                                            !datePopoverOpened
                                          )
                                        }
                                        value={convertMomentDate(el.supplyDate)}
                                      />
                                    </Popover>
                                  </td>
                                )}
                                {showSupplyDate && (
                                  <td>
                                    {el.e_dey
                                      ? format(
                                        new Date(el.supplyDate),
                                        "MMM dd, yyyy"
                                      )
                                      : "None"}
                                  </td>
                                )}

                                {generalSettings?.approveInboundPo && (
                                  <>
                                    {usage === "qhse" ? (
                                      <>
                                        <td>
                                          {el?.warehouseRceiveAssuranceStatus ? (
                                            <span className="text-nowrap">
                                              <b>
                                                {el?.warehouseRceiveAssuranceStatus ===
                                                  "Approve"
                                                  ? "Approved"
                                                  : "Rejected"}
                                              </b>
                                              {" - "}
                                              <i>
                                                {
                                                  el?.warehouseRceiveAssuranceComment
                                                }
                                              </i>
                                              <br />
                                              {
                                                el?.warehouseRceiveAssuranceDate?.split(
                                                  "T"
                                                )[0]
                                              }
                                            </span>
                                          ) : (
                                            <div className="d-flex justify-content-center gap-3">
                                              <Button
                                                disabled={
                                                  qhseStatusActionMutation.isLoading
                                                }
                                                size="sm"
                                                variant="outline-success"
                                                onClick={() =>
                                                  qhseStatusAction({
                                                    ...el,
                                                    action: "Approve",
                                                  })
                                                }
                                              >
                                                Approve
                                              </Button>{" "}
                                              <Button
                                                disabled={
                                                  qhseStatusActionMutation.isLoading
                                                }
                                                size="sm"
                                                variant="danger"
                                                onClick={() =>
                                                  qhseStatusAction({
                                                    ...el,
                                                    action: "Reject",
                                                  })
                                                }
                                              >
                                                Reject
                                              </Button>
                                            </div>
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      <td>
                                        {el?.warehouseRceiveAssuranceStatus && (
                                          <span className="text-nowrap">
                                            <b
                                              style={
                                                el?.warehouseRceiveAssuranceStatus.includes(
                                                  "Approve"
                                                )
                                                  ? { color: "green" }
                                                  : {}
                                              }
                                            >
                                              {el?.warehouseRceiveAssuranceStatus ===
                                                "Approve"
                                                ? "Approved"
                                                : "Rejected"}
                                            </b>
                                            {" - "}
                                            <i>
                                              {
                                                el?.warehouseRceiveAssuranceComment
                                              }
                                            </i>
                                            <br />
                                            {
                                              el?.warehouseRceiveAssuranceDate?.split(
                                                "T"
                                              )[0]
                                            }
                                          </span>
                                        )}
                                      </td>
                                    )}
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </Table>

                      {dueDate && (
                        <div className="d-flex justify-content-start my-3">
                          <h6>
                            Due Date:{" "}
                            {dueDate
                              ? format(new Date(dueDate), "MMM dd, yyyy")
                              : "None"}
                          </h6>
                        </div>
                      )}

                      {tableData?.length > 0 && (
                        <div className="d-flex justify-content-end mb-3">
                          <div className="gridChild gridChildBorderLeftBlue">
                            <div className="gridChildLeft">
                              <p className="gridChld1">
                                {currency(
                                  tableData.reduce(
                                    (a, b) => a + b.amount * b.quantity,
                                    0
                                  ),
                                  {
                                    symbol: "",
                                  }
                                ).format()}
                              </p>
                              <p className="gridChld2">Total</p>
                            </div>
                          </div>
                        </div>
                      )}

                      {showSelect && (
                        <div className="d-flex justify-content-end">
                          {selectedData?.length > 0 && (
                            <>
                              <button
                                onClick={() => setShowNewRequisitionModal(true)}
                                className="btn btn-primary"
                              >
                                Create PO
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>

                    {!disableInput && (
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={sendDataUpdate}
                          className="btn btn-primary"
                          disabled={AddPriceMutation.isLoading}
                        >
                          {AddPriceMutation.isLoading
                            ? "Saving..."
                            : Boolean(IsQuoteSent)
                              ? "Edit Quote"
                              : "Save Quote"}
                        </button>
                      </div>
                    )}

                    {addToInvemtry && usage !== "qhse" ? (
                      <div
                        className={`d-flex ${filterItems()?.warehouseItems?.length > 0
                          ? "justify-content-between"
                          : "justify-content-end"
                          }`}
                      >
                        {filterItems()?.warehouseItems?.length > 0 && (
                          <button
                            onClick={() => setShowUpdateItemsModal(true)}
                            className="btn btn-primary mx-3"
                          >
                            Raise warehouse goods receive note
                          </button>
                        )}

                        {filterItems()?.inventryItems?.length > 0 && (
                          <button
                            onClick={() => setShowUpdateInventoryModal(true)}
                            className="btn btn-primary"
                          >
                            Raise goods receive note
                          </button>
                        )}
                      </div>
                    ) : null}

                    {tableData?.length > 0 && supplyDate && (
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={saveSupplyDate}
                          className="btn btn-primary"
                          disabled={approvePOMutation.isLoading}
                        >
                          {approvePOMutation.isLoading
                            ? "Loading..."
                            : "Accept PO"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </main>
        {ShowNewRequisitionModal && (
          <PORequisitionModal
            setShowNewRequisitionModal={setShowNewRequisitionModal}
            ShowNewRequisitionModal={ShowNewRequisitionModal}
            selectedItems={selectedData}
            theRFQName={theRFQName}
          />
        )}

        {showUpdateInventoryModal && (
          <UpdateItemModal
            showUpdateInventoryModal={showUpdateInventoryModal}
            setShowUpdateInventoryModal={setShowUpdateInventoryModal}
            printGRN={printGRN}
            POItems={filterItems()?.inventryItems?.filter((e) =>
              !generalSettings?.approveInboundPo
                ? true
                : generalSettings?.approveInboundPo
                  ? e?.warehouseRceiveAssuranceStatus === "Approve"
                  : true
            )}
            refetch={refetch}
            onHideIt={() => onHide(false)}
            ignoreAllDuplicateInvoiceChecks={true}
          />
        )}

        {showUpdateItemsModal && (
          <UpdateWarehouseItemModal
            showUpdateInventoryModal={showUpdateItemsModal}
            setShowUpdateInventoryModal={setShowUpdateItemsModal}
            refetch={refetch}
            printGRN={printWHGRN}
            POItems={filterItems()?.warehouseItems?.filter((e) =>
              !generalSettings?.approveInboundPo
                ? true
                : generalSettings?.approveInboundPo
                  ? e?.warehouseRceiveAssuranceStatus === "Approve"
                  : true
            )}
            onHideIt={() => onHide(false)}
            ignoreAllDuplicateInvoiceChecks={true}
          />
        )}

        {showWarehousePO && (
          <AddWarehousePOModal
            show={showWarehousePO}
            onHide={setShowWarehousePO}
            //mainData={selectedPO}
            // allItems={allItems}
            // setAllItems={setAllItems}
            // savedStaff={savedStaff}
            // setSavedStaff={setSavedStaff}
            showedData={DistributedPO}
            PODetails={selectedPO}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

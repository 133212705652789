import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  NoSelectedItemIcon,
  MoneyDollarIcon,
  EditIcon,
  DropdownEyeIcon,
  CheckCircleIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useIsCashier,
  useIsQHSE,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useParams } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import ExpensesSelector from "../utils/ExpensesSelector";
import PageHeader from "../PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import MileStoneModal, { MileStoneDetails } from "./MileStoneModal";
import { useStoreState } from "easy-peasy";

export default function JobDetails() {
  useScrollTop();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const navigate = useNavigate();
  const initialFilterParams = {
    Description: "",
    AccountID: "",
    /*startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,*/
  };

  const { backendUrl } = useAuth();
  const [activeRow, setActiveRow] = useState(null);
  const [showExpensePopover, setShowExpensePopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const isAdmin = useIsAdmin();
  const { jobNumber } = useParams();
  const isQHSE = useIsQHSE();
  const isCashier = useIsCashier();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchJobDetails = async (queryParams) => {
    console.log(queryParams, jobNumber);
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/job/job-details/${jobNumber}?&${queryString.stringify({
        ...queryParams,
        jobNumber,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.milestones = data?.milestones.map((el) => ({
      ...el,
      label: el.milestone,
      value: el.milestone,
    }));
    return data;
  };

  const {
    data = { job: {}, milestones: [] },
    refetch,
    isFetching,
    isSuccess,
    isFetched,
  } = useQuery(
    ["_JOB_DETAILS", queryParams],
    () => fetchJobDetails(queryParams),
    {
      keepPreviousData: false,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedExpense = ({ AccountID, Description }) => {
    setFilterParams({
      ...filterParams,
      AccountID,
      Description,
    });
    setShowExpensePopover(false);
  };

  const manageTransaction = (el) => {
    navigate(`/sales/manage-transaction`, {
      state: {
        TransactionID: el.TransactionID,
      },
    });
  };

  const filesByMileStone = useMemo(() => {
    // const milestones = uniqBy(files, "milestoneNumber");
    const files = data?.job?.files ? JSON.parse(data?.job?.files) : [];
    const milestones = data?.job?.milestones
      ? JSON.parse(data?.job?.milestones)
      : [];

    return milestones.map((el) => ({
      milestone: el.milestone,
      milestoneNumber: el.milestoneNumber,
      date: el.date,
      files: files.filter(
        (file) => file.milestoneNumber === el.milestoneNumber
      ),
    }));
  }, [data?.job]);

  return (
    <main className="inner-page-outlet">
      {/*   <PageHeader
        name="Job Details"
        description=""
        icon={<MoneyDollarIcon />}
      /> */}
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header className="border-bottom    mb-3">
                <h1>
                  {data?.job?.title}
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                {isAdmin && (
                  <Link
                    to={`/reports/job-income-statement?page=1&limit=100&Description=${encodeURIComponent(
                      data?.job.title
                    )}&AccountID=${encodeURIComponent(data?.job.jobNumber)}`}
                    className="btn btn-light-blue"
                  >
                    Job/Contract Report
                  </Link>
                )}
              </header>

              <div className="px-md-4">
                <div className="row">
                  <div className="col-md-8 mb-4">
                    <Table borderless striped className="product-table ">
                      {" "}
                      <tbody>
                        <tr>
                          <td className="fw-bold">Job Number</td>
                          <td>{data?.job?.jobNumber}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Po Number</td>
                          <td>{data?.job?.poNumber}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Po Attachments</td>
                          <td>
                            {" "}
                            {data?.job?.poAttachments &&
                              JSON.parse(data?.job?.poAttachments)?.map(
                                (el, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                  >
                                    <a
                                      href={`${backendUrl}/api/job/view-po-file/${el.systemFileName
                                        }`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-primary"
                                    >
                                      {el?.name}
                                    </a>
                                  </div>
                                )
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Title</td>
                          <td>{data?.job?.title}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Description</td>
                          <td>{data?.job?.description}</td>
                        </tr>
                        <tr>
                          <td className="fw-bold">Customer/Client</td>
                          <td>{customerFullName(data?.job?.customer)}</td>
                        </tr>
                        {!isQHSE && (
                          <>
                            <tr>
                              <td className="fw-bold">Budget</td>
                              <td>
                                {currency(data?.jobBudget?.amount, {
                                  symbol: currenciesOptions.find(
                                    (curr) =>
                                      curr.cc ===
                                      (data?.jobBudget?.currency
                                        ? data?.jobBudget?.currency
                                        : generalSettings?.prevailingCurrency)
                                  )?.symbol,
                                }).format()}
                              </td>
                            </tr>{" "}
                            {isAdmin || isCashier ? (
                              <tr>
                                <td className="fw-bold">Cost</td>
                                <td>
                                  {currency(data?.job?.jobCost, {
                                    symbol: currenciesOptions.find(
                                      (curr) =>
                                        curr.cc ===
                                        (data?.job?.currency
                                          ? data?.job?.currency
                                          : generalSettings?.prevailingCurrency)
                                    )?.symbol,
                                  }).format()}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        )}
                        <tr>
                          <td className="fw-bold">Milestone</td>
                          <td>
                            {data?.job?.hasMilestone ? (
                              <CheckCircleIcon />
                            ) : (
                              <span>...</span>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td className="fw-bold">Entry Date</td>
                          <td>
                            {data?.job?.dateLog
                              ? format(
                                new Date(data?.job?.dateLog),
                                "dd MMM yyyy"
                              )
                              : "..."}
                          </td>
                        </tr>


                        <tr>
                          <td className="fw-bold">Username</td>
                          <td>
                            {data?.job?.username}
                          </td>
                        </tr>

                        <tr>
                          <td className="fw-bold">Acquisition Source</td>
                          <td>
                            {data?.job?.Job_Acquisition_Source}
                          </td>
                        </tr>


                        <tr>
                          <td className="fw-bold">Progress Status</td>
                          <td>
                            {data?.job?.Job_Progress_Status}
                          </td>
                        </tr>


                        <tr>
                          <td className="fw-bold">Contract Award Date</td>
                          <td>
                            {data?.job?.Contract_Award_Date
                              ? format(
                                new Date(data?.job?.Contract_Award_Date),
                                "dd MMM yyyy"
                              )
                              : "..."}
                          </td>
                        </tr>


                        <tr>
                          <td className="fw-bold">Expected Completion Date</td>
                          <td>
                            {data?.job?.Expected_Completion_Date
                              ? format(
                                new Date(data?.job?.Expected_Completion_Date),
                                "dd MMM yyyy"
                              )
                              : "..."}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="col px-3">
                    {data?.job?.hasMilestone ? (
                      <h2 className="h5">Milestones </h2>
                    ) : (
                      <h2 className="h5">Completion Certificate</h2>
                    )}
                    {isQHSE ? <i>*View only</i> : ``}

                    <div style={isQHSE ? { pointerEvents: "none" } : {}}>
                      {filesByMileStone &&
                        filesByMileStone.map((el, index) => (
                          <MileStoneDetails
                            key={index}
                            milestone={el}
                            systemMilestones={data.milestones}
                            selectedJob={data?.job}
                            refetch={() => refetch()}
                          />
                        ))}
                    </div>

                    {isEmpty(filesByMileStone) && isFetched ? (
                      <NoTableItem />
                    ) : null}
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
}

import { cloneDeep, debounce, isEmpty, map, uniqBy, values } from "lodash";
import { Fragment, useMemo, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import {
  convertMomentDate,
  convertToBaseCurrency,
  customerFullName,
  employeeFullName,
  scrollToElement,
  waitFor,
} from "../utils/helpers";
import CustomerSelectModal from "./CustomerSelectModal";
import {
  AddCircleIcon,
  CashSelectIcon,
  ChequeSelectIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  CustomerAccountSelectIcon,
  DeleteIcon,
  DirectTransferSelectIcon,
  MoneyDollarIcon,
  NoSelectedItemIcon,
} from "./Icons";
import PageHeader from "./PageHeader";
import Datetime from "react-datetime";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { FieldArray, Formik, FormikProvider, useFormik } from "formik";
import moment from "moment";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsGovernmentInvexERP,
  useTaxOptions,
} from "../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import ModalLoader from "./utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "./SelectBankDialog";
import { queryActions } from "../utils/reactQueryActions";
import { last } from "lodash";
import * as yup from "yup";
import { first } from "lodash";
import ConfirmDialog from "./ConfirmDialogue";
import { appSettings } from "../config";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { useStoreState } from "easy-peasy";
import ReceiptDialog from "./ReceiptDialog";
import { IsPrivileged } from "./DisplayChildElement";
import { currenciesOptions, currenciesOptionsAll } from "../utils/currencies";
import { FilesAttachments } from "../utils/Attachments";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import eventBus from "../utils/EventBus";

const paymentMethod = [
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },
  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card",
    value: "Credit/Debit Card",
  },
  /*  {
    icon: <CreditMemoSelectIcon />,
    label: "Credit Memo",
    value: "Credit Memo",
  }, */
  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
  {
    icon: <CustomerAccountSelectIcon />,
    label: "Customer Account",
    value: "Customer Account",
  },
];



function duePaymentLabel(el) {
  const currencyText = el?.Payment_Due_Transaction?.currency;
  const amountDue =
    el?.Payment_Due_Transaction?.currency !== el?.prevailingCurrency
      ? currency(el.AmountDue).divide(
        el?.Payment_Due_Transaction?.conversionAmount
      ).value
      : el.AmountDue;

  return `${el.Trans_ID} -  ${el?.Payment_Due_Transaction?.ShipTo} - ${currency(
    amountDue,
    {
      symbol: currenciesOptionsAll.find(
        (curr) =>
          curr.cc === (currencyText ? currencyText : el?.prevailingCurrency)
      )?.symbol,
    }
  ).format()} - ${el?.InvoiceCat || ""}`;
}

function CustomerRow({
  index,
  customer,
  editTable,
  remove,
  customers = [],
  addNewRow,
  backendUrl,
  getCustomerBalanceAndCredit,
  addToParentCustomerList,
}) {
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [duePaymentOptions, setDuePaymentOptions] = useState([]);
  const [invoiceCatOptions, setInvoiceCatOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState([]);

  const initialValues = {
    Post_Date: moment(),
    newInstallment: "",
    Remark: "",
    PaymentType: "Cash",
    duePaymentTransactionID: "",
    chequeNumber: "",
    BankName: "",
    Cust_ID: "",
    InvoiceCat: "None",
    currency: generalSettings?.prevailingCurrency,
    conversionAmount: generalSettings.dollarInBaseCurrency,
    otherTaxValues: [],
    newInstallmentAfterTax: "",
    serviceBar_Code: "",
    serviceItem_Name: "",
    serviceUnitPrice: 0,
    serviceROI: 0,
    sendRecieptEmail: false, sendContractEmail: false
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: yup.object().shape({}),
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Cust_ID) => {
    const customer = customers.find((el) => el?.Cust_ID === Cust_ID);

    if (Cust_ID && isEmpty(customer)) {
      return getCustomerBalanceAndCredit(Cust_ID);
    }

    // GET CUSTOMER BALANCE & CREDIT
    const duePayments = customer?.duePayments ? customer.duePayments : [];
    const invoiceCats = customer?.invoiceCats ? customer.invoiceCats : [];
    const salesRepData = customer?.salesRepData ? customer.salesRepData : [];
    if (
      isEmpty(customer?.balance) ||
      isEmpty(duePayments) ||
      isEmpty(invoiceCats) ||
      isEmpty(salesRepData)
    ) {
      getCustomerBalanceAndCredit(Cust_ID);
    }

    setDuePaymentOptions([
      {
        label: "None",
        value: "",
      },
      ...duePayments.map((el) => ({
        ...el,
        prevailingCurrency,
        label: duePaymentLabel({ ...el, prevailingCurrency }),
        value: el.Trans_ID,
      })),
    ]);

    setInvoiceCatOptions([
      {
        label: "None",
        value: "None",
      },
      ...invoiceCats.map((el) => ({
        label: el.InvoiceCat,
        value: el.InvoiceCat,
      })),
    ]);

    // console.log(salesRepData, "skk");

    setSalesRepOptions([
      {
        label: "None",
        value: "None",
      },
      ...salesRepData.map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      })),
    ]);

    // formik.setFieldValue("newInstallment", duePayments[0]?.AmountDue);
    formik.setFieldValue("Cust_ID", Cust_ID);
  };

  useEffect(() => {
    if (customer?.duePayments) {
      setDuePaymentOptions([
        {
          label: "None",
          value: "",
        },
        ...customer?.duePayments.map((el) => ({
          ...el,
          prevailingCurrency,
          label: duePaymentLabel({ ...el, prevailingCurrency }),
          value: el.Trans_ID,
        })),
      ]);
    }
  }, [customer?.duePayments]);

  useEffect(() => {
    if (customer?.invoiceCats) {
      setInvoiceCatOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer?.invoiceCats.map((el) => ({
          label: el.InvoiceCat,
          value: el.InvoiceCat,
        })),
      ]);
    }
  }, [customer?.invoiceCats]);

  useEffect(() => {
    if (customer?.salesRepData) {
      setSalesRepOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer.salesRepData.map((el) => ({
          label: employeeFullName(el),
          value: el.EmployeeID,
        })),
      ]);
    }
  }, [customer?.salesRepData]);

  useEffectOnce(() => {
    if (customer?.Cust_ID) setUp(customer.Cust_ID);
  });

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }

    editTable({
      index,
      formValues: {
        ...(formValues?.Cust_ID
          ? customers.find((el) => el?.Cust_ID === formValues?.Cust_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
      formik.setFieldValue("currency", bank.currency);
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (formik.values.PaymentType === "Cash") {
      formik.setFieldValue("currency", generalSettings?.prevailingCurrency);
    }
  }, [formik.values.PaymentType]);

  const handleCustomerChange = (value) => {
    formik.setFieldValue("Cust_ID", value);
    if (value) {
      console.log(value);
      setUp(value);
      addNewRow(index);
    }
  };

  const fetchCustomers = async (inputValue, callback) => {
    try {
      let response = await fetch(
        `${backendUrl}/api/customers?page=1&Limit=50&LastName=${inputValue}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const { data } = await response.json();
      if (data.customers)
        callback(
          (data.customers = data.customers.map((el) => ({
            ...el,
            label: customerFullName(el),
            value: el.Cust_ID,
          })))
        );
    } catch (err) {
      console.log(err);
      callback([]);
    }
  };

  const debouncedFetchUsers = _.debounce(fetchCustomers, 500);

  const loadOptions = (inputValue, callback) => {
    try {
      if (_.isEmpty(inputValue)) {
        return callback(null, { options: [] });
      }

      debouncedFetchUsers(inputValue, callback);
    } catch (err) {
      console.log(err)
      return callback(null, { options: [] });
    }
  };

  eventBus.useCustomEventListener(
    "SET_CUSTOMER_TO_PAY",
    ({ customer, serviceItem }) => {
      handleCustomerChange(customer?.value ? customer?.value : "");
      addToParentCustomerList(customer);

      if (serviceItem) {
        formik.setFieldValue("serviceItem_Name", serviceItem.Item_Name);
        formik.setFieldValue("serviceBar_Code", serviceItem.Bar_Code);
        formik.setFieldValue("serviceUnitPrice", serviceItem?.UnitPrice);
        formik.setFieldValue("serviceROI", serviceItem?.ROI);
        formik.setFieldValue("newInstallment", 0);
        // formik.setFieldValue("PaymentType", "Customer Account",)
      }
    }
  );

  const fetchItems = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/items?page=1&Limit=50&q=${inputValue}&Item_Type=Service`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.items)
      callback(
        (data.items = data.items.map((el) => ({
          ...el,
          label: el.Item_Name,
          value: el.Bar_Code,
        })))
      );
  };

  const debouncedFetchItems = debounce(fetchItems, 500);

  const loadItemOptions = (inputValue, callback) => {
    debouncedFetchItems(inputValue, callback);
  };

  return (
    <tr>
      <td>
        {""}
      </td>

      <td>
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={(selected) =>
            formik.setFieldValue("PaymentType", selected?.value)
          }
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        //  isClearable
        />
      </td>

      <td>
        <AsyncSelect
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          data={customers}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={customers?.find((el) => el?.value === formik.values?.Cust_ID)}
          defaultOptions={customers || []}
          onChange={(selection) => {
            handleCustomerChange(selection?.value ? selection?.value : "");
            addToParentCustomerList(selection);
          }}
          id={`newInstallment-customer-${index}`}
          loadOptions={loadOptions}
          isDisabled={true}
        />
      </td>

      <td>
        {/*  SERVICE  */}
        <AsyncSelect
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          menuPlacement="bottom"
          menuPosition="fixed"
          defaultOptions={true}
          isSearchable={true}
          isClearable={true}
          value={{
            label: formik.values.serviceItem_Name,
            value: formik.values.serviceBar_Code,
          }}
          onChange={(selected) => {
            formik.setFieldValue("serviceItem_Name", selected?.label);
            formik.setFieldValue("serviceBar_Code", selected?.value);
            formik.setFieldValue("serviceUnitPrice", selected?.UnitPrice);
            formik.setFieldValue("serviceROI", selected?.ROI);
            formik.setFieldValue("newInstallment", selected?.UnitPrice);
          }}
          loadOptions={loadItemOptions}
          isDisabled={true}
        />
      </td>

      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>

      <td>
        <div className="d-flex gap-3">
          <Form.Group>
            <Select
              classNamePrefix="form-select"
              placeholder="Select Currency"
              isSearchable={false}
              options={currenciesOptions}
              value={currenciesOptions.find(
                (el) => el.value === formik.values.currency
              )}
              onChange={({ value }) => formik.setFieldValue("currency", value)}
              menuPosition="fixed"
              isDisabled
            />
          </Form.Group>

          {formik.values.currency &&
            formik.values.currency !== generalSettings?.prevailingCurrency ? (
            <Form.Group>
              <CurrencyCustomInput
                currencySymbol={"Ex. Rate"}
                name="conversionAmount"
                value={formik.values.conversionAmount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                placeholder="0.00"
              />
            </Form.Group>
          ) : null}
        </div>
      </td>

      {/*  <td>
        <Select
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          menuPlacement="bottom"
          menuPosition="fixed"
          options={duePaymentOptions.filter(
            (el) =>
              el?.Payment_Due_Transaction?.currency === formik.values.currency
          )}
          key={duePaymentOptions}
          isSearchable={false}
          onChange={(selected) => {
            if (selected?.value) {
              const amountDue =
                selected?.Payment_Due_Transaction?.currency &&
                selected?.Payment_Due_Transaction?.currency !==
                  generalSettings?.prevailingCurrency
                  ? currency(selected.AmountDue).divide(
                      selected?.Payment_Due_Transaction?.conversionAmount
                    ).value
                  : selected.AmountDue;

              formik.setFieldValue("duePaymentTransactionID", selected.value);
              formik.setFieldValue("newInstallment", amountDue);
              formik.setFieldValue(
                "currency",
                selected?.Payment_Due_Transaction?.currency || "NGN"
              );
              formik.setFieldValue(
                "conversionAmount",
                selected?.Payment_Due_Transaction?.conversionAmount
              );

              if (customer.Cust_ID === "000101") {
                formik.setFieldValue(
                  "Remark",
                  selected?.Payment_Due_Transaction?.ShipTo || ""
                );
              }

              formik.setFieldValue(
                "Sale_Rep",
                selected?.Payment_Due_Transaction?.Sale_Rep
              );

              formik.setFieldValue("newInstallmentBeforeTax", amountDue);
            }
          }}
          value={duePaymentOptions
            .filter(
              (el) =>
                el?.Payment_Due_Transaction?.currency === formik.values.currency
            )
            .find(
              (el) => el.Trans_ID === formik.values?.duePaymentTransactionID
            )}
        />
      </td> */}

      <td>
        <CurrencyCustomInput
          currencySymbol={""}
          name="newInstallment"
          placeholder="0.00"
          defaultValue=""
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue('newInstallment', value)
          }}
        />
      </td>

      <td>

        <p className="m-1 d-flex justify-content-between"><b>Cost:</b>{" "} &nbsp; {currency(formik.values.serviceUnitPrice, {
          symbol: "",
        }).format()}{" "}</p>
        <p className="m-1 d-flex justify-content-between"><b>ROI:</b>{" "} &nbsp;
          {currency(formik.values.serviceROI, {
            symbol: "",
          }).format()}</p>
        <p className="m-1 d-flex justify-content-between"> <b>Expected Return:</b>{" "} &nbsp;
          {currency(formik.values.serviceROI, {
            symbol: "",
          }).add(formik.values.serviceUnitPrice).format()} </p>
      </td>


      <td>
        {currency(customer.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${formik.touched.Post_Date && !!formik.errors.Post_Date
                  ? "is-invalid"
                  : ""
                  }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>

      <td>{customer.TransactionID}</td>

      {/*    <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={false}
          options={invoiceCatOptions}
          key={invoiceCatOptions}
          onChange={({ value }) => formik.setFieldValue("InvoiceCat", value)}
        />
      </td> */}

      {appSettings.requireSalesRep && (
        <td>
          <Select
            classNamePrefix={"form-select"}
            menuPlacement="bottom"
            menuPosition="fixed"
            isSearchable={false}
            options={salesRepOptions}
            key={salesRepOptions}
            value={salesRepOptions.find(
              (option) => option.value === formik.values.Sale_Rep
            )}
            onChange={({ value }) => formik.setFieldValue("Sale_Rep", value)}
          />
        </td>
      )}
    </tr>
  );
}

export default function EditPlanSignUp() {
  const { planId } = useParams()
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );

  const generalSettings = useStoreState((state) => state.generalSettings);
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const location = useLocation();
  const navigate = useNavigate();

  const setUpCustomerToPay = async () => {
    await waitFor(500);

    // console.log(location.state);

    if (location.state) {
      if (
        location.state?.customerToPay
      )
        eventBus.dispatch("SET_CUSTOMER_TO_PAY", {
          customer: location.state.customerToPay,
          serviceItem: location.state.serviceItem,
        });
    }

    await waitFor(100);
    navigate(location.pathname, { replace: true });
  };

  useEffectOnce(() => {
    setUpCustomerToPay();
  });

  const [tableData, setTableData] = useState([
    {
      Cust_ID: "",
      TransactionID: `STN${Date.now()}1`,
    },
  ]);

  const setSelectedCustomer = (customer) => {
    const lastitem = last(tableData);
    customer.TransactionID = `STN${Date.now()}`;

    if (lastitem.Cust_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}2` },
      ]);
    } else {
      setTableData([
        ...tableData,
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    // console.log(formValues.newInstallment);
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateCustomersLedger = async (payload) => {
    payload.customers = cloneDeep(payload.customers).map((el) => {
      if (el?.currency !== generalSettings?.prevailingCurrency) {
        el.notConvertedInstallment = el.newInstallment;
        const convertedData = convertToBaseCurrency({
          data: {
            newInstallment: el.newInstallment,
            newInstallmentAfterTax: el.newInstallmentAfterTax,
          },
          conversionAmount: el.conversionAmount,
        });
        el.newInstallment = convertedData.newInstallment;
        el.newInstallmentAfterTax = convertedData.newInstallmentAfterTax;
      }

      return el;
    });

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // pick up files
    for (let customer of payload.customers) {
      if (!isEmpty(customer.attachments)) {
        for (let file of customer.attachments) {
          formData.append(
            `customerPaymentFile_${customer.TransactionID}`,
            file
          );
        }
      }
    }

    let response = await fetch(
      `${backendUrl}/api/customers/edit-plan-payments`,
      {
        method: "POST",
        headers: {
          /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const updateCustomersLedgerMutation = useMutation(
    (payload) => updateCustomersLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        let uniqueId = `STN${Date.now()}1`;
        setTableData([...[{ Cust_ID: "", TransactionID: uniqueId }]]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    if (
      !(await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      }))
    ) {
      return;
    }
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyCustomerIndex = table.findIndex((el) => el.Cust_ID === "");
    if (foundEmptyCustomerIndex !== -1) {
      // console.log(foundEmptyCustomerIndex);
      scrollToElement(`#newInstallment-customer-${foundEmptyCustomerIndex}`);
      return toast.error(`Select a Customer`);
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );
    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundEmptyInstallment.Cust_ID}`);
      return toast.error(`Invalid installment added`);
    }

    // Ensure only valid data are sent
    /* console.log(tableData);
    return; */
    const customersData = tableData.filter(
      (el) => el.Cust_ID && el.newInstallment
    );

    // console.log(customersData);
    //  return;

    updateCustomersLedgerMutation.mutate(
      {
        customers: customersData.map(el => ({ ...el, investmentPlanId: planId })),
      },
      {
        onSuccess: async () => {
          navigate(`/reports/plans?customerId=${customersData[0].Cust_ID}&customerName=${customersData[0].label}`)
        },
      }
    );
  };

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/customers?page=1&limit=50`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    }));
    return data;
  };

  const { data = { customers: [] }, isFetching } = useQuery(
    [queryActions.CUSTOMERS],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const addToParentCustomerList = (newCustomerFromSearch) => {
    queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
      if (
        !data.customers.find(
          (el) => el?.Cust_ID === newCustomerFromSearch?.Cust_ID
        )
      ) {
        data.customers = [...data.customers, newCustomerFromSearch];
        return data;
      } else {
        return data;
      }
    });
  };

  const getCustomerBalanceAndCredit = async (Cust_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      //await waitFor(4000);
      let response = await fetch(
        `${backendUrl}/api/customers/balance-and-credit/${Cust_ID}?key=${Date.now()}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats, salesRepData },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
          try {
            data.customers = data?.customers ? data.customers.map((el) =>
              el.Cust_ID === Cust_ID
                ? { ...el, balance, duePayments, invoiceCats, salesRepData }
                : { ...el }
            ) : [];
            return data;
          } catch (err) {
            console.log(err)
            return data
          }

        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats, salesRepData }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  return (
    <IsPrivileged roleName="Receive Payments">
      <main className="create-invoice">
        <PageHeader
          name={"Edit Plan"}
          description={"Update Customer Ledger"}
          icon={<MoneyDollarIcon />}
        />

        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header>
                  <h1>
                    {"Edit Plan"} - #{planId}
                  </h1>
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            <th>Payment Type</th>
                            <th>
                              Business Name
                            </th>
                            <th>Service</th>
                            <th>Remark</th>
                            <th>Currency</th>
                            <th>New Installment</th>
                            <th>Amount</th>
                            <th>Balance on Ledger</th>
                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            {appSettings.requireSalesRep && <th>Sales Rep</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <CustomerRow
                                customers={data?.customers}
                                index={index}
                                customer={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getCustomerBalanceAndCredit={
                                  getCustomerBalanceAndCredit
                                }
                                addToParentCustomerList={
                                  addToParentCustomerList
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>


                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">
                          Haven't selected a Customer yet
                        </h2>
                        <p>
                          You can click +Add Customer Button to add a Customer
                          to the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={updateCustomersLedgerMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      Post
                    </Button>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showCustomerSelectorModal && (
          <CustomerSelectModal
            setShowCustomerSelectorModal={setShowCustomerSelectorModal}
            setSelectedCustomer={setSelectedCustomer}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedCustomers={tableData}
            withInvoiceCat={true}
          />
        )}

        <ModalLoader
          show={updateCustomersLedgerMutation.isLoading || isFetching}
        />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting customer balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}

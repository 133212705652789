import { Fragment, useState, useEffect } from "react";
import "../assets/scss/NavBar.scss";
import { HamburgerIcon } from "./Icons";
import { Dropdown } from "react-bootstrap";
import photoUrl from "./../assets/images/profile.png";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Avatar from "./utils/Avatar";
import { useOnClickOutside, useToggle, useEffectOnce } from "../utils/hooks";
import { useRef } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import ModalLoader from "./utils/ModalLoader";
import { services } from "../config";
import CementModals from "./Cement/CementModals";
import { fetchActionsUtil } from "../utils/helpers";
import UpdateVendorUser from "./UpdateVendorUser";

export default function VendorNavBar() {
  const location = useLocation();
  const { logoutVendor, backendUrl, authVendor, vendorApi } = useAuth();
  const [smShow, setSmShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const navHolder = useRef();

  const logoutUser = async () => {
    try {
      setSmShow(true);
      const res = await fetchActionsUtil(
        `${backendUrl}/api/vendors/logout`,
        "GET"
      );

      if (res.success) {
        logoutVendor();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSmShow(false);
    }
  };

  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if (event.ctrlKey || event.metaKey) {
      switch (charCode) {
        case "l":
          logoutUser();
          break;
        default:
      }
    }
  };

  useEffectOnce(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  });

  useEffect(() => {
    setNavOpen(false);
  }, [location, setNavOpen]);

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const items = useMemo(
    () => [
      {
        name: "Request For Quote",
        to: "Request-For-Quote",
      },
      {
        name: "Quotes",
        to: "Quotes",
      },
      {
        name: "Purchase Order",
        to: "PO",
      },
    ],
    []
  );

  return (
    <nav className="NavBar">
      <div className="d-flex align-items-center">
        <img
          src={`${backendUrl}/images/company-logo.png`}
          alt="Company Logo"
          className="logo"
          width={50}
        />
        <ul ref={navHolder} className={navOpen ? "open" : "closed"}>
          {items.map((item, index) => (
            <li key={index}>
              {!item.childRoutes ? (
                <Link
                  to={item?.to ? item.to : ""}
                  className={` ${location.pathname.startsWith(`/${item.to}`)
                      ? "active fw-6 mx-4"
                      : "mx-4"
                    }`}
                >
                  {item.name}
                </Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    bsPrefix={`toggle ${location.pathname.startsWith(`/${item.to}`)
                        ? "active fw-6 mx-4"
                        : "mx-4"
                      }`}
                  >
                    {item.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {item.childRoutes.map((el, index) => (
                      <Fragment key={index}>
                        {el.to ? (
                          <Dropdown.Item as="div">
                            <Link
                              to={`${item.to ? `/${item.to}` : ""}/${el.to}`}
                            >
                              {el.name}
                            </Link>
                          </Dropdown.Item>
                        ) : null}
                      </Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="d-flex">
        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            className="btn d-flex border-0 align-items-center p-1"
          >
            <Avatar
              className="avatar"
              src={photoUrl}
              name={authVendor?.CompanyName}
              company={authVendor?.Email}
              department={authVendor?.Vendor_Cat}
              userId={authVendor?.Vendor_ID}
              vendor
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
          >
            <Dropdown.Item as="button" onClick={() => setShowProfile(true)}>
              Edit Profile
            </Dropdown.Item>
            <Dropdown.Item as="button">
              {services.frontendUrl === `http://localhost:3000` ? (
                <Link
                  to={`/user-feedBack?UserName=${authVendor?.CompanyName
                    }&Useremail=${authVendor?.Email}`}
                >
                  Feedback
                </Link>
              ) : (
                <a
                  href={`https://feedback.invexonline.com/user-feedBack?UserName=${authVendor?.CompanyName
                    }&Useremail=${authVendor?.Email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Feedback
                </a>
              )}
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => logoutUser()}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button onClick={() => toggleNavOpen()} className="btn p-1 d-md-none">
          <HamburgerIcon />
        </button>
      </div>

      <ModalLoader show={smShow} />

      {/*  Regions, plants cat, sub cats  */}
      <CementModals />
      {showProfile && (
        <UpdateVendorUser
          show={showProfile}
          onHide={() => setShowProfile(false)}
        />
      )}
    </nav>
  );
}

import { useState } from "react";
import { NavLink } from "react-router-dom";

export function ProcurementPlanNav(props) {
  const [items] = useState([
    {
      name: "Demand Plan",
      to: "items-forcast-recorder",
    },
    {
      name: "View Plans",
      to: "demand-plan",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4 pt-5 mb-3" {...props}>
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
